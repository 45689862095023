import { Modal, Button } from "react-bootstrap";

const PanelNamesModal = ({ panelNames, showModal, handleClose }: any) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop={false}
      keyboard={false}
    >
      <Modal.Header closeButton className="bg-light-primary">
        <Modal.Title>Panel Combination</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(panelNames) && (
          <span>Following are the valid combination</span>
        )}
        {Array.isArray(panelNames) ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            {panelNames.map((i: any) => (
              <span
                key={i?.PanelName}
                style={{
                  backgroundColor: "#f0f8ff",
                  color: "#000",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                }}
              >
                {i?.PanelName}
              </span>
            ))}
          </div>
        ) : (
          <div style={{ color: "#ff0000", fontWeight: "bold" }}>
            No combination found
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PanelNamesModal;
