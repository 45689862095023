import React from "react";
import useLang from './../../../../Shared/hooks/useLanguage';

interface CheckBoxProps {
  childChecked: boolean[];
  handleChildChange: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const patientInfo = [
  "First Name",
  "Last Name",
  "Date Of Birth",
  "Patient ID",
  "Gender",
  "Address",
  "City",
  "State",
  "Zip Code",
  "Email",
];

const requisitionInfo = [
  "Requisition Type",
  "Data Of Collection",
  "Time of Collection",
  "Received Date",
  "Validated Date",
  "Status",
  "Accession#",
  "Insurance Type",
  "Lab Code",
];

const insuranceInfo = [
  "Primary Insurance Provider",
  "Provider Name",
  "GroupID",
  "Relationship to insured",
  "Policy ID",
  "Policy Holder DOB",
];

const facilityInfo = [
  "Facility Name",
  "Facility Address",
  "Facility City",
  "Facility State",
  "Facility ZipCode",
  "Facility ID",
  "Physician Name",
  "NPI",
  "SalesRep Name",
];

function CheckBox({ childChecked, handleChildChange }: CheckBoxProps) {
  const { t } = useLang();

  const renderCheckboxes = (labels: string[], startIndex: number) =>
    labels.map((label, index) => (
      <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6" key={index + startIndex}>
        <div className="mb-4">
          <label className="form-check form-check-sm form-check-solid">
            <input
              className="form-check-input h-20px w-20px"
              type="checkbox"
              checked={childChecked[index + startIndex]}
              onChange={handleChildChange(index + startIndex)}
            />
            {t(label)} {/* Translating the label */}
          </label>
        </div>
      </div>
    ));

  return (
    <>
      <div className="row">
        <div className="col-12">
          <p className="mb-3 mt-5">{t("Patient Information")}</p>
        </div>
        {renderCheckboxes(patientInfo.map(label => t(label)), 0)}
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mb-3 mt-5">{t("Requisition Information")}</p>
        </div>
        {renderCheckboxes(requisitionInfo.map(label => t(label)), 10)}
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mb-3 mt-5">{t("Insurance Information")}</p>
        </div>
        {renderCheckboxes(insuranceInfo.map(label => t(label)), 20)}
      </div>

      <div className="row">
        <div className="col-12">
          <p className="mb-3 mt-5">{t("Facility Information")}</p>
        </div>
        {renderCheckboxes(facilityInfo.map(label => t(label)), 26)}
      </div>
    </>
  );
}

export default CheckBox;
