import HttpClient from "../../../HttpClient";
import apiRoutes from "../../../Routes/Routes.json";

export const getPhlebotomistsLookup = () => {
  return HttpClient().post(apiRoutes.Requisition.getPhlebotomistsLookup);
};

interface payloadI {
  facilityId: 0;
  primaryPhlebotomistId: "";
}

export const savePhlebotomistsyAssignment = (payload: payloadI) => {
  return HttpClient().post(
    apiRoutes.Requisition.savePhlebotomistsyAssignment,
    payload
  );
};
