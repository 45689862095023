import { useEffect, useState } from "react";
import { FindIndex } from "Utils/Common/CommonMethods";
import { assignFormValues } from "../Utils/Auth";
import {
  getToxCompendiumIndex,
  getToxTestingOption,
  panelsArrMakerToSend,
} from "../Utils/Common/Requisition";
import MuiSkeleton from "./Common/MuiSkeleton";
import useLang from "./hooks/useLanguage";

const ToxTestingOptionCheckbox = (props: any) => {
  const { t } = useLang()
  const [options, setOptions] = useState<any>([]);
  const [panelsArrToSend, setPanelsArrToSend] = useState<any>([]);
  const [finalizeArray, setFinalizeArray] = useState<any>([]);
  const [inputName, setInputNames] = useState<any>([]);
  useEffect(() => {
    props.Inputs[props?.index].fields.forEach((item: any) => {
      if (item.systemFieldName === "TestingOprtionCheckboxes") {
        setOptions(item.options);
      }
    });
  }, []);
  const handleChange = (e: any) => {
    let inputNameCopy = [...inputName];
    if (e.target.checked) {
      if (!inputNameCopy.includes(e.target.name)) {
        inputNameCopy.push(e.target.name);
      }
    } else {
      inputNameCopy = inputNameCopy.filter((name) => name !== e.target.name);
    }

    let inputsCopy = JSON?.parse(JSON?.stringify(props?.Inputs));
    let toxtestingoptionindex = getToxTestingOption(inputsCopy);
    let compendiumIndex = getToxCompendiumIndex(
      props?.Inputs[toxtestingoptionindex]?.fields
    );
    let updatedFinalizeArray = [...finalizeArray];
    props?.Inputs[toxtestingoptionindex]?.fields?.forEach((i: any) => {
      if (i.systemFieldName === "Compendium") {
        i?.panels?.forEach((j: any, index: any) => {
          if (j.isVisible) {
            if (j.panelType === e.target.name) {
              if (e.target.checked) {
                const panelsArr = panelsArrMakerToSend(
                  index,
                  props?.Inputs[toxtestingoptionindex]?.fields[compendiumIndex]
                    ?.panels[index],
                  panelsArrToSend,
                  e.target.checked
                );
                panelsArr.forEach((panel: any) => {
                  if (
                    !updatedFinalizeArray.some(
                      (p: any) => p.panelID === panel.panelID
                    )
                  ) {
                    updatedFinalizeArray.push(panel);
                  }
                });
                j.isSelected = true;
                j.testOptions.forEach((k: any) => {
                  k.isSelected = true;
                });
              } else {
                updatedFinalizeArray = updatedFinalizeArray.filter(
                  (panel: any) => panel.panelType !== e.target.name
                );

                j.isSelected = false;
                j.testOptions.forEach((k: any) => {
                  k.isSelected = false;
                });
              }
            }
          }
        });
      }
    });

    let newInputs = assignFormValues(
      props.Inputs,
      // props?.dependenceyControls,
      toxtestingoptionindex,
      props?.depControlIndex,
      compendiumIndex,
      updatedFinalizeArray,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    newInputs?.then((res) => {
      let infectiousDataCopy = JSON?.parse(
        JSON?.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props?.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData([...infectiousDataCopy]);
    });
    assignFormValues(
      props.Inputs,
      // props.dependenceyControls,
      props.index,
      props.depControlIndex,
      props.fieldIndex,
      inputNameCopy,
      props.isDependency,
      props.repeatFieldSection,
      props.isDependencyRepeatFields,
      props.repeatFieldIndex,
      props.repeatDependencySectionIndex,
      props.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    setInputNames(inputNameCopy);
    setFinalizeArray(updatedFinalizeArray);
    setPanelsArrToSend(updatedFinalizeArray);
  };

  return (
    <div
      className={
        props?.parentDivClassName
          ? `${props?.parentDivClassName} mb-4`
          : "col-lg-6 col-md-6 col-sm-12 mb-4"
      }
    >
      <div className="row d-flex">
        {options.map(
          (i: any) =>
            i?.isVisable && (
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className="form__group form__group--checkbox">
                  <label
                    className={
                      props?.labelClassName
                        ? `${props?.labelClassName} fw-500 `
                        : "form-check form-check-inline form-check-solid m-0 fw-500"
                    }
                  >
                    <input
                      className="form-check-input h-20px w-20px"
                      type="checkbox"
                      id={i?.id}
                      name={i?.name}
                      value={i?.value}
                      onChange={handleChange}
                      defaultChecked={i?.isSelectedDefault}
                    />
                    {props?.loading ? (
                      <MuiSkeleton height={22} />
                    ) : (
                      <span
                        className={
                          props?.spanClassName
                            ? `${props?.spanClassName} text-break fw-400`
                            : ""
                        }
                      >
                        {i?.name}
                      </span>
                    )}
                  </label>
                  {props.error && (
                    <div className="form__error">{t(props.error)}</div>
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ToxTestingOptionCheckbox;
