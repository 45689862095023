import {
  Id,
  toast as originalToast,
  ToastContent,
  ToastOptions,
} from "react-toastify";

originalToast.reminder = <TData = unknown>(
  content: ToastContent<TData>,
  options: ToastOptions = {}
): Id => {
  const newOptions: ToastOptions = {
    ...options,
    style: {
      ...options.style,
      backgroundColor: "red",
      color: "white",
    },
  };

  return originalToast(content, newOptions);
};

export default originalToast;
