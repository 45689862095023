import { useDispatch } from "react-redux";
import { savePdfUrls } from "Redux/Actions/Index";
import { showFieldValue } from "./showFields";
import { useEffect } from "react";

const RequisitionFields = (props: any) => {
  const dispatch = useDispatch();

  const openDocViewer = () => {
    let path = JSON.parse(props?.fieldsInfo?.defaultValue);
    window.open("/docs-viewer", "_blank");
    dispatch(savePdfUrls(path?.[0]?.fileUrl));
  };

  if (props?.fieldsInfo?.systemFieldName === "RepeatStart") {
    return (
      <>
        {Array.isArray(props?.fieldsInfo?.repeatFields) &&
          props.fieldsInfo.repeatFields.map((item: any, index: number) => (
            <div key={index}>{showFieldValue(item, undefined, props)}</div>
          ))}
      </>
    );
  } else {
    return <div>{showFieldValue(props.fieldsInfo, openDocViewer, props)}</div>;
  }
};

export default RequisitionFields;
