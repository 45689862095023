import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Loader } from "../../Shared/Common/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowUp, ArrowDown } from "../../Shared/Icons";
import ManageSalesRepServices from "../../Services/ManageSalesRep/ManageSalesRepServices";
import { AxiosResponse } from "axios";
import { StringRecord } from "../../Shared/Type";
import { TableContainer } from "@mui/material";
import useLang from "Shared/hooks/useLanguage";
const ArchiveTab = () => {
  const initialSearchQuery = {
    id: "",
    firstName: "",
    lastName: "",
    salesRepEmail: "",
    salesRepPhone: "",
  };

  const queryDisplayTagNames: StringRecord = {
    firstName: "First Name",
    lastName: "Last Name",
    salesRepEmail: "Email",
    salesRepPhone: "Phone",
  };

  const initialSearchRequest = {
    id: "",
    firstName: "",
    lastName: "",
    salesRepEmail: "",
    salesRepPhone: "",
    status: true,
  };

  const { t } = useLang();
  //============================================================================================
  //====================================  PAGINATION START =====================================
  //============================================================================================
  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [total, setTotal] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [triggerSearchData, setTriggerSearchData] = useState(false);
  const [archivesalesrepList, setArchiveSalesRepList] = useState<any>([]);
  const [searchRequest, setSearchRequest] = useState<any>(initialSearchRequest);
  const [loading, setLoading] = useState(false);
  const nextPage = () => {
    if (curPage < Math.ceil(total / pageSize)) {
      setCurPage(curPage + 1);
    }
  };
  const showPage = (i: number) => {
    setCurPage(i);
  };
  const prevPage = () => {
    if (curPage > 1) {
      setCurPage(curPage - 1);
    }
  };
  useEffect(() => {
    setTotalPages(Math.ceil(total / pageSize));
    const pgNumbers = [];
    for (let i = curPage - 2; i <= curPage + 2; i++) {
      if (i > 0 && i <= totalPages) {
        pgNumbers.push(i);
      }
    }
    setPageNumbers(pgNumbers);
  }, [total, curPage, pageSize, totalPages]);

  useEffect(() => {
    LoadDataForArchive(false);
  }, [curPage, curPage, triggerSearchData]);

  //============================================================================================
  //====================================  PAGINATION END =======================================
  //============================================================================================
  useEffect(() => {
    setCurPage(1);
    LoadDataForArchive(true);
  }, [pageSize]);
  const initialSorting = {
    sortColumn: "id",
    sortDirection: "desc",
  };
  const [sort, setSorting] = useState<any>(initialSorting);
  const searchRef = useRef<any>(null);

  const handleSort = async (columnName: any) => {
    searchRef.current.id = searchRef.current.id
      ? searchRef.current.id === "asc"
        ? (searchRef.current.id = "desc")
        : (searchRef.current.id = "asc")
      : (searchRef.current.id = "asc");
    sort.sortColumn = columnName;
    sort.sortDirection = searchRef.current.id;
    setSorting((preVal: any) => {
      return {
        ...preVal,
        sortingOrder: searchRef?.current?.id,
        clickedIconData: columnName,
      };
    });
    LoadDataForArchive(false);
  };
  //Searched tags code Start
  const [searchedTags, setSearchedTags] = useState<string[]>([]);
  const handleTagRemoval = (clickedTag: string) => {
    setSearchRequest((prevSearchRequest: any) => {
      return {
        ...prevSearchRequest,
        [clickedTag]: (initialSearchQuery as any)[clickedTag],
      };
    });
  };

  useEffect(() => {
    const uniqueKeys = new Set<string>();
    for (const [key, value] of Object.entries(searchRequest)) {
      if (value) {
        uniqueKeys.add(key);
      }
    }
    setSearchedTags(Array.from(uniqueKeys));
  }, [searchRequest]);

  useEffect(() => {
    if (searchedTags.length === 1) resetSeachQuery();
  }, [searchedTags.length]);

  //Searched tags code End

  const InputSearchRequest = (key: string, value: any) => {
    setSearchRequest((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const LoadDataForArchive = (reset: boolean) => {
    setLoading(true);
    ManageSalesRepServices.ManageSalesRepData({
      pageIndex: curPage,
      pageSize: pageSize,
      requestModel: reset ? initialSearchRequest : searchRequest,
      sortColumn: reset ? initialSorting.sortColumn : sort?.sortColumn,
      sortDirection: reset ? initialSorting.sortDirection : sort?.sortDirection,
    })
      .then((res: AxiosResponse) => {
        setArchiveSalesRepList(res?.data?.result);
        setTotal(res?.data?.total);
        setLoading(false);
      })
      .catch((err: any) => {
        console.trace(err);
        setLoading(false);
      });
  };

  const resetSeachQuery = () => {
    setSearchRequest(initialSearchRequest);
    setSorting(initialSorting);
    LoadDataForArchive(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      LoadDataForArchive(false);
    }
  };

  return (
    <div className="card-body py-2">
      <div className="d-flex gap-4 flex-wrap mb-2">
        {searchedTags.map((tag: any) =>
          tag === "status" ? null : (
            <div
              className="d-flex align-items-center cursor-pointer gap-1 p-2 rounded bg-light"
              onClick={() => handleTagRemoval(tag)}
            >
              <span className="fw-bold">{queryDisplayTagNames[tag]}</span>
              <i className="bi bi-x"></i>
            </div>
          )
        )}
      </div>
      <div className="responsive-flexed-actions gap-2 mb-2 d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center">
        <div className="d-flex align-items-center gap-2 responsive-flexed-actions">
          <div className="d-flex align-items-center">
            <span className="fw-400 mr-3">{t("Records")}</span>
            <select
              className="form-select w-125px h-33px rounded py-2"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-dropdown-parent="#kt_menu_63b2e70320b73"
              data-allow-clear="true"
              onChange={(e) => {
                setPageSize(parseInt(e.target.value));
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="50" selected>
                50
              </option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div
          className="d-flex align-items-center gap-2"
          onClick={() => {
            setCurPage(1);
            setTriggerSearchData((prev: any) => !prev);
          }}
        >
          <button
            className="btn btn-linkedin btn-sm fw-500"
            aria-controls="Search"
            onClick={() => LoadDataForArchive(false)}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm btn-secondary--icon fw-500"
            id="kt_reset"
            onClick={resetSeachQuery}
          >
            <span>
              <span>{t("Reset")}</span>
            </span>
          </button>
        </div>
      </div>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          paddingTop: "0",
        }}
      >
        <div className="table_bordered overflow-hidden">
          <TableContainer
            sx={{
              maxHeight: "calc(100vh - 100px)",
              "&::-webkit-scrollbar": {
                width: 7,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#fff",
              },
              "&:hover": {
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "var(--kt-gray-400)",
                  borderRadius: 2,
                },
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "var(--kt-gray-400)",
                borderRadius: 2,
              },
            }}
            className="shadow-none"
          >
            <Table
              aria-label="sticky table collapsible"
              className="table table-cutome-expend table-bordered table-sticky-header table-head-2-bg table-bg table-head-custom table-vertical-center border-0 mb-0"
            >
              <TableHead>
                <TableRow className="h-40px">
                  <TableCell>
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      value={searchRequest.firstName}
                      className="form-control bg-white rounded-2 fs-8 h-30px"
                      placeholder="Search..."
                      onChange={(e: any) =>
                        InputSearchRequest(e.target.name, e.target.value)
                      }
                      onKeyDown={handleKeyPress}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      value={searchRequest.lastName}
                      className="form-control bg-white rounded-2 fs-8 h-30px"
                      placeholder="Search..."
                      onChange={(e: any) =>
                        InputSearchRequest(e.target.name, e.target.value)
                      }
                      onKeyDown={handleKeyPress}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      id="salesRepEmail"
                      type="text"
                      name="salesRepEmail"
                      value={searchRequest.salesRepEmail}
                      className="form-control bg-white rounded-2 fs-8 h-30px"
                      placeholder="Search..."
                      onChange={(e: any) =>
                        InputSearchRequest(e.target.name, e.target.value)
                      }
                      onKeyDown={handleKeyPress}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      id="salesRepPhone"
                      type="text"
                      name="salesRepPhone"
                      value={searchRequest.salesRepPhone}
                      className="form-control bg-white rounded-2 fs-8 h-30px"
                      placeholder="Search..."
                      onChange={(e: any) =>
                        InputSearchRequest(e.target.name, e.target.value)
                      }
                      onKeyDown={handleKeyPress}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="min-w-150px"
                    sx={{ width: "max-content" }}
                  >
                    <div
                      onClick={() => handleSort("firstName")}
                      className="d-flex justify-content-between cursor-pointer"
                      id=""
                      ref={searchRef}
                    >
                      <div style={{ width: "max-content" }}>
                        {t("First Name")}
                      </div>

                      <div className="d-flex justify-content-center align-items-center mx-4 mr-0">
                        <ArrowUp
                          CustomeClass={`${
                            sort.sortingOrder === "desc" &&
                            sort.clickedIconData === "firstName"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0 "`}
                        />
                        <ArrowDown
                          CustomeClass={`${
                            sort.sortingOrder === "asc" &&
                            sort.clickedIconData === "firstName"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0`}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="min-w-150px"
                    sx={{ width: "max-content" }}
                  >
                    <div
                      onClick={() => handleSort("lastName")}
                      className="d-flex justify-content-between cursor-pointer"
                      id=""
                      ref={searchRef}
                    >
                      <div style={{ width: "max-content" }}>
                        {t("Last Name")}
                      </div>

                      <div className="d-flex justify-content-center align-items-center mx-4 mr-0">
                        <ArrowUp
                          CustomeClass={`${
                            sort.sortingOrder === "desc" &&
                            sort.clickedIconData === "lastName"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0 "`}
                        />
                        <ArrowDown
                          CustomeClass={`${
                            sort.sortingOrder === "asc" &&
                            sort.clickedIconData === "lastName"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0`}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="min-w-150px"
                    sx={{ width: "max-content" }}
                  >
                    <div
                      onClick={() => handleSort("salesRepEmail")}
                      className="d-flex justify-content-between cursor-pointer"
                      id=""
                      ref={searchRef}
                    >
                      <div style={{ width: "max-content" }}> {t("Email")}</div>

                      <div className="d-flex justify-content-center align-items-center mx-4 mr-0">
                        <ArrowUp
                          CustomeClass={`${
                            sort.sortingOrder === "desc" &&
                            sort.clickedIconData === "salesRepEmail"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0 "`}
                        />
                        <ArrowDown
                          CustomeClass={`${
                            sort.sortingOrder === "asc" &&
                            sort.clickedIconData === "salesRepEmail"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0`}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="min-w-150px"
                    sx={{ width: "max-content" }}
                  >
                    <div
                      onClick={() => handleSort("salesRepPhone")}
                      className="d-flex justify-content-between cursor-pointer"
                      id=""
                      ref={searchRef}
                    >
                      <div style={{ width: "max-content" }}>{t("Phone")}</div>

                      <div className="d-flex justify-content-center align-items-center mx-4 mr-0">
                        <ArrowUp
                          CustomeClass={`${
                            sort.sortingOrder === "desc" &&
                            sort.clickedIconData === "salesRepPhone"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0 "`}
                        />
                        <ArrowDown
                          CustomeClass={`${
                            sort.sortingOrder === "asc" &&
                            sort.clickedIconData === "salesRepPhone"
                              ? "text-success fs-7"
                              : "text-gray-700 fs-7"
                          }  p-0 m-0`}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableCell colSpan={8} className="">
                    <Loader />
                  </TableCell>
                ) : (
                  archivesalesrepList?.map((item: any) => (
                    <>
                      <TableRow>
                        <TableCell>{item?.firstName}</TableCell>
                        <TableCell>{item?.lastName}</TableCell>
                        <TableCell>{item.salesRepEmail}</TableCell>
                        <TableCell>{item.salesRepPhone}</TableCell>
                      </TableRow>
                    </>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ==========================================================================================
        //====================================  PAGINATION START =====================================
        //============================================================================================ */}
        <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-sm-between align-items-center mt-4">
          {/* =============== */}
          <p className="pagination-total-record mb-0">
            {Math.min(pageSize * curPage, total) === 0 ? (
              <span>Showing 0 to 0 of {total} entries</span>
            ) : (
              <span>
                Showing {pageSize * (curPage - 1) + 1} to
                {Math.min(pageSize * curPage, total)} of Total
                <span> {total} </span> entries
              </span>
            )}
          </p>
          {/* =============== */}
          <ul className="d-flex align-items-center justify-content-end custome-pagination mb-0 p-0">
            <li className="btn btn-lg p-2 h-33px" onClick={() => showPage(1)}>
              <i className="fa fa-angle-double-left"></i>
            </li>
            <li className="btn btn-lg p-2 h-33px" onClick={prevPage}>
              <i className="fa fa-angle-left"></i>
            </li>

            {pageNumbers.map((page: any) => (
              <li
                key={page}
                className={`px-2 ${
                  page === curPage
                    ? "font-weight-bold bg-primary text-white h-33px"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => showPage(page)}
              >
                {page}
              </li>
            ))}

            <li className="btn btn-lg p-2 h-33px" onClick={nextPage}>
              <i className="fa fa-angle-right"></i>
            </li>
            <li
              className="btn btn-lg p-2 h-33px"
              onClick={() => {
                if (totalPages === 0) {
                  showPage(curPage);
                } else {
                  showPage(totalPages);
                }
              }}
            >
              <i className="fa fa-angle-double-right"></i>
            </li>
          </ul>
        </div>
        {/* ==========================================================================================
        //====================================  PAGINATION END =====================================
        //============================================================================================ */}
      </Box>
    </div>
  );
};

export default ArchiveTab;
