import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useLang from "Shared/hooks/useLanguage";

interface CheckboxProps {
  id: string;
  type: string;
  name: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}
interface IValues {
  listone: string | number | undefined;
  listtwo: string | number | undefined;
}
interface IObj {
  id: string | never;
  name: string | never;
  list: boolean | never;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  type,
  name,
  handleChange,
  isChecked,
}) => {
  return (
    <label className="form-check form-check-sm form-check-solid me-5 border">
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleChange}
        checked={isChecked}
        className="form-check-input"
      />
    </label>
  );
};
interface IProps {
  facilities: any;
  setValues: React.SetStateAction<any>;
  values: any;
  sports2: any;
  setSports2: any;
}

const Facilities: React.FC<IProps> = ({
  facilities,
  setValues,
  values,
  sports2,
  setSports2,
}) => {
  const { t } = useLang()
  const [isCheckAll, setIsCheckAll] = useState({
    testList: false,
    selectedTestList: false,
  });
  const [isCheckTestList, setisCheckTestList] = useState<any[]>([]);
  const [isCheckedSelectedList, setisCheckedSelectedList] = useState<any[]>([]);
  const [selectedItemsToPop, setSelectedItemsToPop] = useState<any>([]);
  const [dragItemIndex, setDragItemIndex] = useState<any>();
  const [checkedList, setCheckedList] = useState({
    testList: [],
    selectedTestList: [],
  });
  const [dragOverItemIndex, setDragOverItemIndex] = useState<IValues>({
    listone: "",
    listtwo: "",
  });

  const [sports, setSports] = useState([]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;

    if (name === "testList") {
      if (!isCheckAll.testList) {
        // Get an array of all the facility IDs and names
        const allFacilities = filteredSports.map((li: any) => ({
          id: li.id,
          facilityName: li.facilityName,
        }));

        setCheckedList((preVal: any) => {
          return {
            ...preVal,
            testList: [...allFacilities],
          };
        });

        setisCheckTestList(allFacilities.map((facility: any) => facility.id));
      } else {
        setCheckedList((preVal: any) => {
          return {
            ...preVal,
            testList: [],
          };
        });

        setisCheckTestList([]);
      }

      setIsCheckAll((prevVal: any) => ({
        ...prevVal,
        testList: !prevVal.testList,
      }));
    }

    if (name === "selectedtestList") {
      if (!isCheckAll.selectedTestList) {
        const allFacilities = filteredSports2.map((li: any) => ({
          id: li.id,
          facilityName: li.facilityName,
        }));
        setCheckedList((preVal: any) => {
          return {
            ...preVal,
            selectedTestList: [...allFacilities],
          };
        });

        setisCheckedSelectedList(allFacilities.map((li: any) => li.id));
      }
      if (isCheckAll.selectedTestList) {
        setisCheckedSelectedList([]);
      }
      setIsCheckAll((preVal: any) => {
        return {
          ...preVal,
          selectedTestList: !isCheckAll.selectedTestList,
        };
      });
    }
  };

  useEffect(() => {
    setSports(
      facilities
        .map((facility: any) => ({
          id: facility.value,
          facilityName: facility.label,
        }))
        .filter(
          (facility: any) =>
            !sports2?.some((item: any) => item.id === facility.id)
        )
    );
    setValues((preVal: any) => {
      return {
        ...preVal,
        facilitiesIds: sports2?.map((item: any) => item.id),
      };
    });
  }, [sports2, facilities]);

  const handleChangeTestList = (e: any, id: any, name: any, index: any) => {
    // Check if the item is already in isCheckTestList
    const isSelected = isCheckTestList.includes(id);

    if (isSelected) {
      // Remove the item from isCheckTestList
      setisCheckTestList(isCheckTestList.filter((item) => item !== id));
    } else {
      // Add the item to isCheckTestList
      setisCheckTestList([...isCheckTestList, id]);
    }

    // Then, update checkedList.testList
    setCheckedList((prevVal: any) => {
      if (isSelected) {
        // If it was selected, remove it from testList
        return {
          ...prevVal,
          testList: checkedList.testList.filter((item: any) => item.id !== id),
        };
      } else {
        // If it wasn't selected, add it to testList
        return {
          ...prevVal,
          testList: [
            ...checkedList.testList,
            { id, facilityName: name, index },
          ],
        };
      }
    });
  };

  const handleChangeSelectedTestList = (
    e: any,
    id: any,
    name: any,
    index: any
  ) => {
    // Check if the item is already in isCheckedSelectedList
    const isSelected = isCheckedSelectedList.includes(id);

    if (isSelected) {
      // Remove the item from isCheckedSelectedList
      setisCheckedSelectedList(
        isCheckedSelectedList.filter((item) => item !== id)
      );
      // Remove the item from selectedItemsToPop
      setSelectedItemsToPop(
        selectedItemsToPop.filter((item: any) => item.id !== id)
      );
      // Remove the item from checkedList.selectedTestList
      setCheckedList((prevVal) => {
        return {
          ...prevVal,
          selectedTestList: checkedList.selectedTestList.filter(
            (item: any) => item.id !== id
          ),
        };
      });
    } else {
      // Add the item to isCheckedSelectedList
      setisCheckedSelectedList([...isCheckedSelectedList, id]);
      // Add the item to selectedItemsToPop
      setSelectedItemsToPop([
        ...selectedItemsToPop,
        { id, facilityName: name, index },
      ]);
      // Add the item to checkedList.selectedTestList
      setCheckedList((prevVal: any) => {
        return {
          ...prevVal,
          selectedTestList: [
            ...checkedList.selectedTestList,
            { id, facilityName: name, index },
          ],
        };
      });
    }
  };
  useEffect(() => {
    forwardPush();
  }, [checkedList.testList]);

  useEffect(() => {
    backwardPush();
  }, [checkedList.selectedTestList]);
  const forwardPush = () => {
    if (checkedList.testList.length !== 0) {
      let originalArrCopy = Array.isArray(sports2) ? [...sports2] : [];
      let arrWithCheckedItems = Array.isArray(checkedList.testList)
        ? [...checkedList.testList]
        : [];

      let concatedArray = [
        ...(originalArrCopy || []),
        ...(arrWithCheckedItems || []),
      ];
      let currentArray = [...sports];

      // Remove the pushed items from the original sports array
      let updatedSports = currentArray.filter((item: any) => {
        return !concatedArray.find((el) => el.id === item.id);
      });

      setSports(updatedSports);
      setSports2(concatedArray);
      setValues((preVal: any) => {
        return {
          ...preVal,
          facilitiesIds: [
            ...new Set(
              (preVal?.facilitiesIds || []).concat(
                concatedArray.map((item: any) => item.id)
              )
            ),
          ],
        };
      });
      setCheckedList((preVal: any) => {
        return {
          ...preVal,
          testList: [],
        };
      });
      setisCheckTestList([]);
      setIsCheckAll((prevVal) => ({
        ...prevVal,
        testList: false, // Uncheck the checkbox
      }));
    }
  };

  const backwardPush = () => {
    if (checkedList.selectedTestList.length !== 0) {
      let orignalArrCopy = [...sports];
      let arrWithCheckedItems: any = [...checkedList.selectedTestList];

      let concatedArray = [
        ...new Set(orignalArrCopy.concat(arrWithCheckedItems)),
      ];
      let currentArray = [...sports2];

      let updatedSports = currentArray.filter((item: any) => {
        return !concatedArray.find((el: any) => el.id === item.id);
      });
      setSports2(updatedSports);
      setSports(concatedArray);
      setCheckedList((preVal: any) => {
        return {
          ...preVal,
          selectedTestList: [],
        };
      });
      setValues((preVal: any) => {
        return {
          ...preVal,

          facilitiesIds: updatedSports.map((item: any) => item.id),
        };
      });
      setisCheckedSelectedList([]);
      setIsCheckAll((prevVal) => ({
        ...prevVal,
        selectedTestList: false, // Uncheck the checkbox
      }));
    }
  };
  //////////////////////////// Search ///////////////////////////////
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");

  // const [filteredFacilities, setFilteredFacilities] = useState([])
  const filteredSports = sports.filter((facility: any) =>
    facility?.facilityName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredSports2 = sports2?.filter((facility: any) =>
    facility?.facilityName.toLowerCase().includes(searchQuery2.toLowerCase())
  );
  //////////////////////////// Search ///////////////////////////////
  return (
    <>
      <div className="card shadow-sm mb-3 rounded border border-warning mt-5">
        <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning min-h-40px">
          <h6 className="text-warning mb-0">{t("Facilities")}</h6>
        </div>
        <div className="card-body py-md-3 py-2">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 ">
                <span className="fw-bold">{t("All Facilities")}</span>
                <input
                  type="text"
                  name=""
                  className="form-control bg-white mb-3 mb-lg-0 h-30px rounded-2 fs-8 w-100"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />

                <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                  <div className="px-4 h-30px d-flex align-items-center rounded bg-secondary">
                    {/* <Checkbox
                      type="checkbox"
                      name="testList"
                      id="selectAll"
                      handleChange={handleSelectAll}
                      isChecked={isCheckAll.testList}
                    /> */}
                    <span className="fw-bold">{t("All List")}</span>
                  </div>

                  <ul className="list-group rounded-0 list-group-even-fill h-225px scroll">
                    {filteredSports?.map((items: any, index: number) => (
                      <li
                        key={index}
                        className={
                          dragOverItemIndex?.listone === index
                            ? "list-group-item next-position px-2 py-1 border-0 cursor-pointer "
                            : "list-group-item px-2 py-1 border-0 cursor-pointer"
                        }
                        onClick={(e) =>
                          handleChangeTestList(
                            e,
                            items?.id,
                            items?.facilityName,
                            index
                          )
                        }
                      >
                        <div className="d-flex">
                          <React.Fragment key={items?.id}>
                            {/* <Checkbox
                              type="checkbox"
                              name={items?.facilityName}
                              id={items?.id + 1}
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                handleChangeTestList(
                                  e,
                                  items?.id,
                                  items?.facilityName,
                                  index,
                                )
                              }
                              isChecked={isCheckTestList.includes(items?.id)}
                            /> */}
                            {items?.facilityName}
                          </React.Fragment>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/*<div className="col-xl-1 col-lg-1 col-md-12 col-sm-12 gap-2 d-flex flex-column justify-content-center align-items-center">
                 <button
                  onClick={forwardPush}
                  className="btn btn-icon btn-sm fw-bold btn-warning mb-2"
                >
                  <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i>
                </button>
                <button
                  onClick={backwardPush}
                  className="btn btn-icon btn-sm fw-bold btn-upload btn-icon-light"
                >
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                </button> 
              </div>*/}

              <div className="mb-2 col-lg-6 col-md-12 col-sm-12 ">
                <span className="fw-bold">{t("Selected Facilities")}</span>
                <input
                  type="text"
                  name=""
                  className="form-control bg-white mb-3 mb-lg-0 h-30px rounded-2 fs-8 w-100"
                  placeholder="Search..."
                  value={searchQuery2}
                  onChange={(e) => setSearchQuery2(e.target.value)}
                />
                <div className="mt-2 border-1 border-light-dark border rounded overflow-hidden">
                  <div className="px-4 h-30px d-flex align-items-center rounded bg-secondary">
                    {/* <Checkbox
                      type="checkbox"
                      name="selectedtestList"
                      id="selectAll"
                      handleChange={handleSelectAll}
                      isChecked={isCheckAll.selectedTestList}
                    /> */}
                    <span className="fw-bold">{t("Selected List")}</span>
                  </div>

                  <ul className="list-group rounded-0 list-group-even-fill h-225px scroll">
                    {filteredSports2?.map((item: any, index: number) => (
                      <li
                        key={index}
                        className={
                          dragOverItemIndex?.listtwo === index
                            ? "list-group-item next-position px-2 py-1 border-0 cursor-pointer "
                            : "list-group-item px-2 py-1 border-0 cursor-pointer"
                        }
                        onClick={(e) => {
                          if (!item?.isDisabled) {
                            handleChangeSelectedTestList(
                              e,
                              item.id,
                              item.facilityName,
                              index
                            );
                          } else {
                            toast.info("Primary facility can not be removed");
                          }
                        }}
                      >
                        <div className="d-flex">
                          <React.Fragment key={item.id}>
                            {/* <Checkbox
                              type="checkbox"
                              name={item.facilityName}
                              id={item.id}
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                handleChangeSelectedTestList(
                                  e,
                                  item.id,
                                  item.facilityName,
                                  index,
                                )
                              }
                              isChecked={isCheckedSelectedList.includes(
                                item.id,
                              )}
                            /> */}
                            {item.facilityName}
                          </React.Fragment>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
