import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Fab } from "@mui/material";
import { AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DocsViewer from "../Pages/DocsViewer/DocsViewer";
import SelectFacility from "../Pages/Lab/SelectFacility";
import SelectDefaultLab from "../Pages/Lab/SelectPortal";
import {
  setAdminUserId,
  setRefreshToken,
  setUserInfo,
} from "../Redux/Actions/Index";
import FacilityService from "../Services/FacilityService/FacilityService";
import TrueMedDashboardLayout from "../Shared/Dashboard/TrueMedDashboardLayout";
import useAuth from "../Shared/hooks/useAuth";
import useLogoutListener from "../Shared/hooks/useLogoutListener";
import {
  Encrypt,
  getParameterByName,
  getRoutes,
  getToken,
} from "../Utils/Auth";
import {
  setFaviconAndTitle,
  setValueIntoSessionStorage,
} from "../Utils/Common/CommonMethods";
import RoutesObj from "./Routes";

const AuthenticatedRoutes = () => {
  const navigate = useNavigate();
  const logout = useLogoutListener();

  const pageLinks = useSelector((reducers: any) => reducers.Reducer?.links);
  const menus = useSelector((reducers: any) => reducers.Reducer?.Menus);
  const webInfo = useSelector((reducers: any) => reducers.Reducer?.webInfo);

  const location = useLocation();
  const pathname = location.pathname;

  const extractPageIdFromPath = (pathname: string) => {
    const regexPatterns = [
      /\/dynamic-form\/(.+)/,
      /\/dynamic-grid\/(.+)/,
      /\/dynamic-one-ui\/(.+)/,
      /\/dynamic-split-pane\/(.+)/,
    ];

    for (const pattern of regexPatterns) {
      const match = pathname.match(pattern);
      if (match && match[1]) {
        try {
          return parseInt(window.atob(match[1]), 10);
        } catch (error) {
          console.error("Error decoding page ID from URL:", error);
          return null;
        }
      }
    }

    return null;
  };

  const buildBreadcrumb = (pageId: number | null) => {
    let breadCrumb: any[] = [];
    if (Array.isArray(menus) && menus.length > 0 && pageId) {
      menus.forEach((module: any) => {
        let moduleFind = module.claims.find((claim: any) => {
          return claim.id === pageId;
        });

        if (moduleFind) {
          breadCrumb.push(
            {
              name: module.module,
            },
            {
              name: moduleFind.name,
            }
          );
        }
      });
    }
    return breadCrumb;
  };

  useEffect(() => {
    const pageIdFromPath = extractPageIdFromPath(pathname);

    if (pageIdFromPath !== null) {
      setValueIntoSessionStorage("pageId", pageIdFromPath);

      const breadCrumb = buildBreadcrumb(pageIdFromPath);
      setValueIntoSessionStorage("currentBreadcrumb", breadCrumb);

      // Forcing a re-render for breadcrumbs
      window.dispatchEvent(new Event("storage"));
      return;
    }

    if (pageLinks && Array.isArray(pageLinks) && pageLinks.length > 0) {
      // First, check for an exact match
      let pageIdFromUrl = pageLinks.find((link: any) => {
        if (link.linkUrl) {
          return pathname === link.linkUrl;
        }
      })?.id;

      // If no exact match, check for a partial match
      if (!pageIdFromUrl) {
        pageIdFromUrl = pageLinks.find((link: any) => {
          if (link.linkUrl) {
            return pathname.includes(link.linkUrl);
          }
        })?.id;
      }

      console.log(pageIdFromUrl, "pageIdFromUrl");

      const breadCrumb = buildBreadcrumb(pageIdFromUrl);
      setValueIntoSessionStorage("pageId", pageIdFromUrl);
      setValueIntoSessionStorage("currentBreadcrumb", breadCrumb);

      // Forcing a re-render for breadcrumbs
      window.dispatchEvent(new Event("storage"));
    }
  }, [pathname, pageLinks]);

  async function logoutIfLoggedIn() {
    if (
      window.location.pathname.includes("ResetPassword") ||
      window.location.pathname.includes("InitializePassword")
    ) {
      const urlToNavigateTo = window.location.pathname;

      await logout();
      window.location.pathname = urlToNavigateTo;
    }
  }

  useEffect(() => {
    logoutIfLoggedIn();
  }, [window.location.pathname, navigate]);

  const checkToken = useCallback(() => {
    const token = getToken();
    if (token != null) return token;
  }, []);

  useLayoutEffect(() => {
    const smartLogo = webInfo?.smartLogoUrl;
    const title = webInfo?.title;
    if (smartLogo && title) {
      setFaviconAndTitle(smartLogo, title);
    }
  }, [webInfo]);

  return (
    <Routes>
      {checkToken() && window.location.pathname === "/login" && (
        <Route path="/login" element={<Navigate to={"/MyFavourite"} />} />
      )}
      <Route path="/" element={<TrueMedDashboardLayout />} />
      <Route path="/*" element={<TrueMedDashboardLayout />} />
      <Route path="*" element={<SelectDefaultLab />}></Route>
      <Route path="/SelectLab" element={<SelectDefaultLab />}></Route>
      <Route path="/SelectFacility" element={<SelectFacility />}></Route>
      <Route path="/docs-viewer" element={<DocsViewer />}></Route>
    </Routes>
  );
};

const Main = () => {
  const loadingState = useSelector((state) => state);
  const [hover, setHover] = useState(false);
  const { LoginRoute }: any = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  let tokendata = getParameterByName("key", window.location.href);
  const adminId = useSelector((state: any) => state.Reducer?.adminId);
  const goBackToAdminPortal = async () => {
    try {
      const res: AxiosResponse = await FacilityService.GoToPortal(adminId);
      dispatch(setAdminUserId(null));
      const data = res.data;
      const encryptData: any = Encrypt(JSON.stringify(res.data));
      sessionStorage.setItem("userinfo", encryptData);
      dispatch(setRefreshToken(data.refreshToken));
      dispatch(setUserInfo(encryptData));
      await LoginRoute(data);
    } catch (error) {
      console.error("Error navigating to admin portal:", error);
    }
  };

  useEffect(() => {
    refreshedToken();
  }, [location.pathname, tokendata]);

  useEffect(() => {
    if (tokendata) {
      checkToken();
    }
  }, [tokendata]);

  const refreshedToken = () => {
    const token = getToken();
    if (token != null) return token;

    // Check if token is expired
    try {
      const decodedToken: any = jwtDecode(token);
      const { exp } = decodedToken;

      // Check if `exp` exists and if the token is expired
      if (!exp || Date.now() >= exp * 1000) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  let userinfoExist = localStorage?.getItem("userinfo");

  if (userinfoExist && tokendata) {
    localStorage.removeItem("userinfo");
  }

  const checkToken = () => {
    if (tokendata) {
      refreshedToken();
    }
  };

  useEffect(() => {
    const collection = document.getElementsByClassName(
      "MuiLinearProgress-root"
    );
    if ((loadingState as any)?.LoadingIndicatorReducer?.loaderVisible) {
      collection[0] && collection[0]?.classList.remove("d-none");
    } else {
      collection[0] && collection[0]?.classList.add("d-none");
    }
  }, [loadingState]);

  const BackPanelButton = () => {
    return (
      <div className="position-fixed" style={{ right: "20px", bottom: "20px" }}>
        <Fab
          variant="extended"
          className={`bg-primary text-white ${
            hover ? "w-auto" : "circle-button"
          }`}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={goBackToAdminPortal}
        >
          <KeyboardReturnIcon className="mr-1" />
          {hover && <span>Return to Portal</span>}
        </Fab>
      </div>
    );
  };

  return (
    <>
      {refreshedToken() ? (
        <AuthenticatedRoutes />
      ) : (
        <Routes>{getRoutes(RoutesObj.UnAuthRoutes)}</Routes>
      )}
      {adminId ? <BackPanelButton /> : null}
    </>
  );
};

export default Main;
