import React, { useEffect, useRef } from "react";
import store from "Redux/Store/AppStore";
import InsuranceService from "Services/InsuranceService/InsuranceService";
import { useBilling } from "Shared/hooks/useBilling";
import { getToxTestingOption } from "../../../Utils/Common/Requisition";
import useLang from "./../../hooks/useLanguage";

const Radio2 = (props: any) => {
  const { t } = useLang();
  const { addBillingInfo } = useBilling();

  const inputElementReactSelect = useRef(props?.name);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElementReactSelect.current ||
      props?.errorFocussedInput === inputElementReactSelect.current.id
    ) {
      const defaultSelectedOption = props?.RadioOptions?.find(
        (choice: any) => choice?.isSelectedDefault
      );
      if (!defaultSelectedOption) {
        inputElementReactSelect.current.focus();
      }
    }
  }, [props?.errorFocussedInput]);

  let inputsCopy = JSON?.parse(JSON?.stringify(props?.Inputs));
  let toxtestingoptionindex = getToxTestingOption(inputsCopy);

  useEffect(() => {
    const defaultSelectedOption = props?.RadioOptions?.find(
      (choice: any) => choice?.isSelectedDefault
    );
    const e = {
      target: {
        value: defaultSelectedOption?.value,
        attributes: {
          "data-name": { value: props?.systemFieldName },
          "data-id": { value: defaultSelectedOption?.id },
        },
        checked: false,
      },
    };
    if (defaultSelectedOption) {
      props?.onChange(
        e,
        defaultSelectedOption.value,
        defaultSelectedOption.value,
        defaultSelectedOption?.label
      );
    }
  }, []);

  const fetchedOptions = useRef(false);

  // Fetch insurance providers based on insurance ID
  const getOptions = async (id: any) => {
    fetchedOptions.current = true;
    try {
      const res = await InsuranceService.GetInsuranceProvidersDropdown(id);
      return res?.data;
    } catch (err) {
      console.error("Error fetching insurance providers", err);
    }
  };

  const autoBillingSelection = async () => {
    if (props?.name === "BillingType") {
      const billingSelected = props?.RadioOptions?.find(
        (option: any) => option.label === props?.defaultValue
      );

      if (billingSelected) {
        const response = await getOptions(billingSelected?.optionDataID);
        addBillingInfo(props?.fieldIndex, response);
      }
    }
  };

  useEffect(() => {
    // this is for pre-fetching insurance
    autoBillingSelection();
  }, [props.defaultValue]);
  console.log(props, "props?.RadioOptions");
  return (
    <>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName} mb-5`
            : "col-lg-6 col-md-6 col-sm-12 mb-5"
        }
        id={props?.name}
        ref={inputElementReactSelect}
        tabIndex={-1}
      >
        <label
          className={`d-block mb-2 fw-500 text-dark${
            props.required ? " required" : ""
          }`}
        >
          {t(props?.label)}
        </label>

        <div className="row g-2">
          {props?.RadioOptions?.map((choice: any) => {
            const phySign = store.getState()?.ReqReducer?.phySign;
            const isPhysicianSignAvailable = phySign;

            // Hide options with `optionDataId` 32 and 605 if `physicianSign` is not available
            if (
              !isPhysicianSignAvailable &&
              choice?.value?.toLowerCase() ===
                "Use Physician Signature".toLowerCase()
            ) {
              return null;
            }

            return (
              choice?.isVisable && (
                <label
                  key={choice?.id}
                  className={
                    props.name === "POCTestResult"
                      ? "col-12 fw-400 d-flex justify-content-start align-items-start"
                      : "col-6 fw-400 d-flex justify-content-start align-items-start"
                  }
                  htmlFor={choice?.name + choice?.id}
                  id={choice?.name + choice?.id}
                >
                  <input
                    className="form-check-input ifuser flex-column-auto h-20px w-20px"
                    type="radio"
                    name={
                      props?.name +
                      props?.repeatFieldIndex +
                      window.crypto.randomUUID()
                    }
                    id={choice?.id}
                    data-nameCheck={props?.name}
                    data-name={choice?.name}
                    data-id={choice?.id}
                    data-optionDataId={choice?.optionDataID}
                    value={choice?.value}
                    checked={
                      props.defaultValue
                        ? choice?.value.split(" ").join("") ===
                          props?.defaultValue.split(" ").join("")
                        : choice?.isSelectedDefault
                    }
                    defaultChecked={choice.isSelectedDefault ? true : false}
                    onChange={async (e: any) => {
                      const { value } = e.target;
                      props?.onChange(e, value, choice?.value, choice?.label);
                      const optionDataId =
                        e.target.getAttribute("data-optiondataid");
                      const nameCheck = e.target.getAttribute("data-namecheck");

                      if (nameCheck === "BillingType") {
                        const response = await getOptions(optionDataId);
                        addBillingInfo(props?.fieldIndex, response);
                      }
                      const dataId = e.target.getAttribute("data-id");
                      localStorage.setItem("insurnceID", dataId);
                      localStorage.setItem("insuranceOptionId", optionDataId);
                      // Check for Tox testing option based on specimen type
                      const isToxTesting =
                        props?.ArrayReqId === 3 && props.sectionId === 7;
                      if (isToxTesting) {
                        props?.Inputs[toxtestingoptionindex]?.fields?.forEach(
                          (field: any) => {
                            const panels = field?.panels;
                            panels.forEach((panel: any) => {
                              panel.testOptions.forEach((testOption: any) => {
                                if (testOption.specimenType === choice.label) {
                                  panel.isVisible = true;
                                } else {
                                  panel.isVisible = false;
                                }
                              });
                            });
                          }
                        );
                      }
                    }}
                    disabled={props?.disabled}
                    required={props.required}
                  />
                  <span className="ps-2 text-break">{t(choice?.label)}</span>
                </label>
              )
            );
          })}
        </div>
        {props.error && (
          <div className="form__error">
            <span>{t(props.error)}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Radio2);
