import HttpClient from "../../HttpClient.ts";
import apiRoutes from "../../Routes/Routes.json";

const getICD10AssigmnetList = (searchRequest) => {
  return HttpClient().post(
    `/${apiRoutes.ICD10AssigmnetManagement.getICD10AssigmnetList}`,
    searchRequest
  );
};
const AddICD10Assigmnet = (searchRequest) => {
  return HttpClient().post(
    `/${apiRoutes.ICD10AssigmnetManagement.AddICD10Assigmnet}`,
    searchRequest
  );
};
const ICD10CodeLookUp = (ICD10Id) => {
  return HttpClient().get(
    `/${apiRoutes.ICD10AssigmnetManagement.ICD10CodeLookUp}/${ICD10Id}`
  );
};
const ReferenceLabLookUp = () => {
  return HttpClient().get(
    `/${apiRoutes.ICD10AssigmnetManagement.ReferenceLabLookUp}`
  );
};
const FacilityLookUp = () => {
  return HttpClient().get(
    `/${apiRoutes.ICD10AssigmnetManagement.FacilityLookUp}`
  );
};
const RequsitionLookUp = () => {
  return HttpClient().get(
    `/${apiRoutes.ICD10AssigmnetManagement.RequsitionLookUp}`
  );
};
const GetLabTypeAgainstRefLabLookUp = (labid) => {
  return HttpClient().get(
    `/${apiRoutes.ICD10AssigmnetManagement.GetLabTypeAgainstRefLabLookUp}/${labid}`
  );
};
const ChangeStatusICD10Assigment = (searchRequest) => {
  return HttpClient().post(
    `/${apiRoutes.ICD10AssigmnetManagement.ChangeStatusICD10Assigment}`,
    searchRequest
  );
};
const GetICD10Codes = (searchRequest) => {
  return HttpClient().post(
    `/${apiRoutes.ICD10AssigmnetManagement.GetICD10Codes}`,
    searchRequest
  );
};
const saveICD10Codes = (searchRequest) => {
  return HttpClient().post(
    `/${apiRoutes.ICD10AssigmnetManagement.saveICD10Codes}`,
    searchRequest
  );
};
const changeStatusICD10Code = (searchRequest) => {
  return HttpClient().post(
    `/${apiRoutes.ICD10AssigmnetManagement.changeStatusICD10Code}`,
    searchRequest
  );
};
const SearchICD10 = (query, key) => {
  let path = apiRoutes.ICD10AssigmnetManagement.SearchICD10;
  path = path.replace("query", query);
  path = path.replace("key", key);
  return HttpClient().get(`/${path}`);
};
const PanelLookUp = () => {
  return HttpClient().get(`/${apiRoutes.ICD10AssigmnetManagement.PanelLookUp}`);
};
const getMedicationAssignmentList = (searchRequest) => {
  return HttpClient().get(
    `/${apiRoutes.ICD10AssigmnetManagement.getMedicationAssignmentlist}`,
    searchRequest
  );
};
const AssigmentService = {
  getICD10AssigmnetList,
  AddICD10Assigmnet,
  ICD10CodeLookUp,
  ReferenceLabLookUp,
  FacilityLookUp,
  RequsitionLookUp,
  PanelLookUp,
  GetLabTypeAgainstRefLabLookUp,
  ChangeStatusICD10Assigment,
  GetICD10Codes,
  saveICD10Codes,
  changeStatusICD10Code,
  SearchICD10,
  getMedicationAssignmentList,
};

export default AssigmentService;
