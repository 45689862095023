import HttpClient from "HttpClient";

const getPatientDemographicsList = (searchRequest) => {
  return HttpClient().post(
    `api/PatientManagement/GetAllPatientsV2`,
    searchRequest
  );
};
const ViewPatientDemoGraphics = (obj) => {
  return HttpClient().post(`api/PatientManagement/ViewPatient`, obj);
};
const getPatientRequisitionOrder = (patientId) => {
  return HttpClient().get(
    `api/PatientManagement/Patient/${patientId}/Requisitions`
  );
};

const downloadPatientReport = (payload) => {
  return HttpClient().post(`/api/PatientViewReport/Generate-pdf`, payload);
}

const PatientService = {
  getPatientDemographicsList,
  getPatientRequisitionOrder,
  ViewPatientDemoGraphics,
  downloadPatientReport
};

export default PatientService;
