import { useEffect, useState } from "react";
import { genUniqueId } from "../Utils/Common";
import DynamicFormInputs from "./DynamicFormInputs";
import { useBilling } from "./hooks/useBilling";
import useLang from "./hooks/useLanguage";
import { CrossIcon } from "./Icons";

const RepeatInputs = (props: any) => {
  const { t } = useLang();
  const { removeBillingInfo } = useBilling();
  const addFields = (index: number, Inputs: any, fieldIndex: number) => {
    let inputsCopy = JSON.parse(JSON.stringify(Inputs));
    let copyRepeatField = JSON.parse(
      JSON.stringify(inputsCopy[index].fields[fieldIndex])
    );
    let id = genUniqueId();
    //copyRepeatField.repeatFields.unshift(crossObj);
    copyRepeatField.id = id;

    copyRepeatField?.repeatDependencyControls?.forEach(
      (_: any, depindex: number, arrayItself: any) => {
        arrayItself[depindex].dependecyFields.forEach(
          (_: any, DepFieldindex: number, arrayItselDepFields: any) => {
            // removing 'Client Bill and Self Pay' ticket > 86eqn2frz
            if (arrayItself[depindex]?.systemFieldName === "BillingType") {
              arrayItself[depindex]?.options?.map(
                (fieldOption: any, fieldIndex: number) => {
                  if (fieldOption?.id === 41 || fieldOption?.id === 24) {
                    delete arrayItself[depindex]?.options?.[fieldIndex];
                  }
                }
              );
            }

            if (
              arrayItselDepFields[DepFieldindex]?.displayType.includes("d-none")
            ) {
              // return;
            } else {
              arrayItselDepFields[DepFieldindex].displayType =
                arrayItselDepFields[DepFieldindex].displayType + " " + "d-none";
            }
          }
        );
      }
    );
    copyRepeatField.repeatFields.forEach(
      (_: any, repeatfieldIndex: number, arrayItself: any) => {
        // removing 'Client Bill and Self Pay' ticket > 86eqn2frz
        console.log(arrayItself, "32423rw4523r23r");
        if (arrayItself[repeatfieldIndex]?.systemFieldName === "BillingType") {
          arrayItself[repeatfieldIndex]?.options?.map(
            (fieldOption: any, fieldIndex: number) => {
              if (fieldOption?.id === 41 || fieldOption?.id === 24) {
                delete arrayItself[repeatfieldIndex]?.options?.[fieldIndex];
              }
            }
          );
        }
        if (
          arrayItself[repeatfieldIndex]?.systemFieldName ===
          "RelationshipToInsured"
        ) {
          arrayItself[repeatfieldIndex].selectedText = "";
          arrayItself[repeatfieldIndex]?.options?.forEach((i: any) => {
            if (i) {
              i.isSelectedDefault = false;
            }
          });
        }
        if (
          arrayItself[repeatfieldIndex]?.systemFieldName === "SubscriberDOB" ||
          arrayItself[repeatfieldIndex]?.systemFieldName === "SubscriberName"
        ) {
          arrayItself[repeatfieldIndex].validationExpression = "";
        }
        if (
          arrayItself[repeatfieldIndex]?.systemFieldName === "BillingType" &&
          props.reqId
        ) {
          arrayItself[repeatfieldIndex]?.options?.forEach((i: any) => {
            if (i) {
              i.isSelectedDefault = false;
            }
          });
        }

        if (arrayItself[repeatfieldIndex]?.displayType.includes("d-none")) {
          let removeDisplayNone = arrayItself[
            repeatfieldIndex
          ]?.displayType.replace("d-none", " ");
          arrayItself[repeatfieldIndex].displayType = removeDisplayNone;
        }
      }
    );

    copyRepeatField.repeatFields?.forEach((repeatFieldInfo: any) => {
      repeatFieldInfo.defaultValue = "";
    });
    var json = JSON.stringify(copyRepeatField);

    var newCopyWithOutRef = JSON.parse(json);

    inputsCopy[index].fields.splice(fieldIndex + 1, 0, newCopyWithOutRef);
    props?.setInputs(inputsCopy);
    if (fieldIndex > 0) {
      copyRepeatField.repeatFields.shift();
    }
  };

  const fields = props.Inputs[props.index].fields;
  const repeatIndexes = fields.reduce((indexes: any, item: any, idx: any) => {
    if (item?.uiType === "Repeat") {
      indexes.push(idx);
    }
    return indexes;
  }, []);

  const removeFields = (billingInfoSectionid: number, fieldsId: number) => {
    removeBillingInfo(props.fieldIndex);

    let inputsCopy = [...props.Inputs];
    inputsCopy[fieldsId].fields.splice(billingInfoSectionid, 1);
    props?.setInputs && props?.setInputs(inputsCopy);
  };
  const [checkbox, setCheckbox] = useState(false);
  const handleChangeCheckbox = (e: any) => {
    if (e.target.checked) {
      setCheckbox(true);
      props.setIns(true);
    } else {
      setCheckbox(false);
      props.setIns(false);
    }
  };

  function FindBillingTypeLength() {
    let countBilling = 0;
    props.Inputs.forEach((input: any) => {
      if (input.sectionId === 5) {
        input.fields.forEach((field: any) => {
          if (field.displayFieldName.toLowerCase() === "repeat start") {
            countBilling++;
          }
        });
      }
    });
    return countBilling;
  }

  useEffect(() => {
    if (props.reqId) {
      const billingTypeLength = FindBillingTypeLength();
      if (billingTypeLength != 1) {
        setCheckbox(false);
        props.setIns(false);
        props.setDisableCheckbox(true);
      } else {
        setCheckbox(true);
        props.setIns(true);
        props.setDisableCheckbox(false);
      }
    }
    if (props.patientId) {
      const billingTypeLength = FindBillingTypeLength();
      if (billingTypeLength != 1) {
        setCheckbox(false);
        props.setIns(false);
        props.setDisableCheckbox(true);
      } else {
        setCheckbox(true);
        props.setIns(true);
        props.setDisableCheckbox(false);
      }
    }
  }, []);

  console.log(props, "disableCheckbox");
  return (
    <>
      <span
        className={
          props.fieldIndex == repeatIndexes[0]
            ? "d-none"
            : "d-flex justify-content-end"
        }
      >
        <span
          onClick={() => {
            console.log(
              props?.repeatControlLength,
              "props?.repeatControlLength"
            );
            if (props?.repeatControlLength == 2) {
              props.setDisableCheckbox(false);
            }
            removeFields(props?.fieldIndex, props?.index);
          }}
        >
          <CrossIcon className="fs-2hx text-gray-700 bi bi-x cursor-pointer" />
        </span>
      </span>
      {props.fieldIndex === repeatIndexes[0] &&
      props.requisitionflow !== "requisitionbilling" ? (
        <hr />
      ) : null}
      {props?.repeatFields?.map((field: any, index: number) => (
        <>
          <DynamicFormInputs
            uiType={field?.uiType}
            removeUi={field?.removeUi ? field?.removeUi : false}
            label={field?.displayFieldName}
            displayType={field?.displayType}
            visible={field?.visible}
            required={field?.required}
            RadioOptions={field?.uiType === "RadioButton" ? field?.options : ""}
            formData={props?.formData}
            setFormData={props?.setFormData}
            selectOpt={field?.options}
            formState={props?.formState}
            setFormState={props?.setFormState}
            index={props?.index}
            fieldIndex={props?.fieldIndex}
            repeatFieldIndex={index}
            Inputs={props?.Inputs}
            setInputs={props?.setInputs}
            sysytemFieldName={field?.systemFieldName}
            isDependent={false}
            dependenceyControls={props?.Section?.dependenceyControls}
            repeatFields={field?.repeatFields}
            searchID={field?.searchID}
            isShown={props.isShown}
            setIsShown={props.setIsShown}
            repeatInputs={true}
            pageId={props?.pageId}
            repeatFieldSection={true}
            sectionName={props?.sectionName}
            defaultValue={field?.defaultValue ?? ""}
            infectiousData={props.infectiousData}
            setInfectiousData={props.setInfectiousData}
            enableRule={field?.enableRule}
            controlId={field?.controlId}
            mask={field.mask}
            errorFocussedInput={props?.errorFocussedInput}
            field={field}
            //  ValidationCheckOnClick={props.ValidationCheckOnClick}
            //ref={ref}
          />
        </>
      ))}
      {props?.repeatDependencyControls?.map(
        (options: any, repeatDependencySectionIndex: number) => (
          <>
            {options?.dependecyFields?.map(
              (depfield: any, repeatDepFieldIndex: number) => (
                <>
                  <DynamicFormInputs
                    uiType={depfield?.uiType}
                    label={depfield?.displayFieldName}
                    sysytemFieldName={depfield?.systemFieldName}
                    displayType={
                      depfield?.displayType +
                      " " +
                      options?.name +
                      " " +
                      options?.name +
                      options.optionID
                    }
                    visible={depfield?.visible}
                    required={depfield?.required}
                    RadioOptions={
                      depfield?.uiType === "RadioButton"
                        ? depfield?.options
                        : ""
                    }
                    formData={props?.formData}
                    setFormData={props?.setFormData}
                    formState={props?.formState}
                    setFormState={props?.setFormState}
                    index={props?.index}
                    repeatDependencySectionIndex={repeatDependencySectionIndex}
                    repeatDepFieldIndex={repeatDepFieldIndex}
                    fieldIndex={props?.fieldIndex}
                    Inputs={props?.Inputs}
                    setInputs={props?.setInputs}
                    depOptionID={options.optionID}
                    dependenceyControls={props?.Section?.dependenceyControls}
                    isDependent={true}
                    searchID={depfield?.searchID}
                    dependencyAction={options?.dependecyAction}
                    isShown={props.isShown}
                    setIsShown={props.setIsShown}
                    depfield={depfield}
                    repeatInputs={true}
                    pageId={props?.pageId}
                    sectionName={props?.sectionName}
                    repeatFieldSection={true}
                    isDependencyRepeatFields={true}
                    defaultValue={depfield?.defaultValue ?? ""}
                    setInfectiousData={props.setInfectiousData}
                    enableRule={depfield.enableRule}
                    mask={depfield.mask}
                    errorFocussedInput={props?.errorFocussedInput}
                    //  ValidationCheckOnClick={props.ValidationCheckOnClick}
                  />
                </>
              )
            )}
          </>
        )
      )}
      <div>
        {" "}
        {props?.fieldIndex === 0 ? (
          <>
            <label className="form-check form-check-inline form-check-solid m-0 fw-500">
              <input
                className="form-check-input h-20px w-20px"
                type="checkbox"
                onClick={handleChangeCheckbox}
                checked={checkbox}
                disabled={props?.disableCheckbox}
              />
              <span>No Secondary Insurance</span>
            </label>
          </>
        ) : null}
      </div>
      <div className="col-12">
        <hr />
        <button
          onClick={(e: any) => {
            addFields(props?.index, props?.Inputs, props?.fieldIndex);
            setCheckbox(false);
            props.setIns(false);
            props.setDisableCheckbox(true);
          }}
          disabled={checkbox}
          className={`${
            props.fieldIndex == repeatIndexes[repeatIndexes.length - 1]
              ? ""
              : "d-none"
          } btn btn-info`}
        >
          <i style={{ fontSize: "16px" }} className="fa">
            &#xf067;
          </i>
          {props.Inputs[props?.index].fields[props?.fieldIndex].mask
            ? t(props.Inputs[props?.index].fields[props?.fieldIndex].mask)
            : t("Add Another")}
        </button>
      </div>
    </>
  );
};

export default RepeatInputs;
