import { Box, Collapse, TableCell, TableRow } from "@mui/material";
import PatientReqOrder from "Pages/Patient/PatientReqOrder";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { savePdfUrls } from "Redux/Actions/Index";
import PatientServices from "Services/PatientServices/PatientServices";
import RequisitionType from "Services/Requisition/RequisitionTypeService";
import PermissionComponent from "Shared/Common/Permissions/PermissionComponent";
import useLang from "Shared/hooks/useLanguage";
import { AddIcon, CrossIcon, DoneIcon, RemoveICon } from "Shared/Icons";
import AddNewInputs from "./AddNewInputs";
import { savePhlebotomistsyAssignment } from "Services/Requisition/WorkLog";

function Row({
  item,
  columnsHeader,
  columnActions,
  expandableColumnsHeader,
  loadData,
  bulkActionLength,
  setBulkIds,
  bulkIds,
  inputFields,
  setRows,
  rows,
  setApiData,
  apiData,
  delayedCall,
  setInputFields,
  tabIdToSend,
  columnDataActions,
  isBulkEdit,
  tabId,
  searchQuery
}: any) {
  const { t } = useLang();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  // const handleActionClick = async (action: any, data: any) => {
  //   if (action.buttontype === 3) {
  //     const isAnyRowActive = rows.some((row: any) => row.rowStatus === true);

  //     if (isAnyRowActive) {
  //       console.log(
  //         "Another row is already active. Cannot add or activate another row."
  //       );
  //       return;
  //     }

  //     setInputFields(
  //       inputFields.map((field: any) => ({
  //         ...field,
  //         show: field?.isIndividualEditable,
  //       }))
  //     );
  //     const updatedRows = rows.map((row: any) => {
  //       if (row.Id === item.Id) {
  //         return { ...row, rowStatus: true };
  //       }
  //       return row;
  //     });
  //     setApiData({
  //       url: action.actionUrl,
  //       body: action.jsonBody,
  //       method: action.methodType,
  //     });

  //     setRows(updatedRows);
  //     return;
  //   }

  //   if (action.buttontype === 5) {
  //     setInputFields(
  //       inputFields.map((field: any) => ({
  //         ...field,
  //         show: !field?.isIndividualEditable,
  //       }))
  //     );
  //     const updatedRows = rows.map((row: any) => {
  //       if (row.Id === item.Id) {
  //         return { ...row, rowStatus: true };
  //       }
  //       return row;
  //     });
  //     setApiData({
  //       url: action.actionUrl,
  //       body: action.jsonBody,
  //       method: action.methodType,
  //     });

  //     setRows(updatedRows);
  //     return;
  //   }

  //   if (action.actionUrl && action.buttontype === 1) {
  //     let path: any = action.actionUrl.split("/")[0];
  //     navigate(`${path}/${btoa(data.Id)}`, { replace: true });
  //   } else if (action.actionUrl && action.buttontype === 2) {
  //     const payload = {
  //       TableId: 0,
  //       actionName: action.actionName,
  //       ids: [data.Id],
  //     };
  //     await PatientServices.makeApiCallForDynamicGrid(
  //       action.actionUrl,
  //       action.methodType ?? null,
  //       payload
  //     );
  //     loadData(false);
  //   }
  // };

  // const isExpandable = () =>
  //   columnsHeader?.some((column: any) => column.isExpandData);

  const handleIdsSelections = (id: number) => {
    setBulkIds((prevIds: number[]) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((existingId) => existingId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const handleRowRemove = () => {
    delayedCall();
  };

  const handleChange = (name: string, value: string, id: number) => {
    console.log(name, value, id, "kansdnaks");

    setRows((curr: any) =>
      curr.map((x: any) =>
        x.id === id
          ? {
              ...x,
              [name]: value,
            }
          : x
      )
    );
  };
  

  const makeAPICALL = async () => {
    try {
      savePhlebotomistsyAssignment(item?.rowStatus ? item : searchQuery);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleActionConfiguration = async (item: any, column: any) => {
  //   if (item.ActionConfiguration) {
  //     let actionConfigurationArray = JSON.parse(item.ActionConfiguration);

  //     let actionConfiguration = actionConfigurationArray.find(
  //       (action: any) => column?.columnKey === action.Column
  //     );

  //     if (actionConfiguration) {
  //       let path = actionConfiguration.URL;
  //       let method = actionConfiguration.APIMethod;

  //       path = path.replace(
  //         "{Id}/{TabId}/{Column}",
  //         `${item.Id}/${tabIdToSend}/${actionConfiguration.Column}`
  //       );
  //       const response = await PatientServices.APICALL(path, method);
  //       let dataToReplaceWith = response?.data?.data?.[0];

  //       const indexToReplace = rows.findIndex(
  //         (item: any) => item.Id === dataToReplaceWith?.Id
  //       );

  //       if (indexToReplace !== -1) {
  //         const updatedBooksSplice = [...rows];
  //         updatedBooksSplice.splice(indexToReplace, 1, dataToReplaceWith);
  //         setRows(updatedBooksSplice);
  //       }
  //     }
  //   }
  // };

  // const handleFileScenario = (action: any) => {
  //   if (action.buttontype === 4) {
  //     handleFileDownload(action);
  //   } else {
  //     handleFileView(action);
  //   }
  // };

  // const handleFileView = async (action: any) => {
  //   let url = action.actionUrl.replace("{FileUrl}", item.FileUrl);

  //   window.open("/docs-viewer", "_blank");
  //   dispatch(savePdfUrls(url));
  // };

  // const handleFileDownload = async (action: any) => {
  //   const downloadButton = document.getElementsByClassName("fa-download");

  //   if (downloadButton) {
  //     let url = action.actionUrl.replace("{FileUrl}", item.FileUrl);

  //     RequisitionType.ShowBlob(url).then((res: any) => {
  //       window.open(res?.data?.Data.replace("}", ""), "_blank");
  //     });
  //   } else {
  //     console.log("The button does not have the download icon.");
  //   }
  // };

  // const anyPassed = columnsHeader?.map(
  //   (column: any, columnHeaderIndex: number) =>
  //     column?.isShowOnUi && !column?.isExpandData && column.isShow
  // );

  console.log(item, "asdnkasdk")

  return (
    <>
      <TableRow>
        {tabId === 0 || tabId === 1 ? (
          <TableCell className="w-20px min-w-20px">
            <span onClick={() => setOpen(!open)}>
              {open ? (
                <button className="btn btn-icon btn-icon-light btn-sm fw-bold btn-table-expend-row rounded h-20px w-20px min-h-20px">
                  <RemoveICon />
                </button>
              ) : (
                <button className="btn btn-icon btn-icon-light btn-sm fw-bold btn-primary rounded h-20px w-20px min-h-20px">
                  <AddIcon />
                </button>
              )}
            </span>
          </TableCell>
        ) : null}
        {tabId === 0 || tabId === 1 ? (
          <TableCell>
            <label className="form-check form-check-sm form-check-solid">
              <input
                type="checkbox"
                className="form-check-input"
                checked={bulkIds.includes(item.Id)}
                onChange={() => handleIdsSelections(item.Id)}
              />
            </label>
          </TableCell>
        ) : null}
        {!item.rowStatus ? (
          tabId === 0 || tabId === 1 || tabId === 2 || tabId === 4 ? (
            <TableCell className="min-w-50px w-50px">
              <div className="d-flex justify-content-center rotatebtnn">
                <DropdownButton
                  className="p-0 del-before btn btn-light-info btn-active-info btn-sm btn-action table-action-btn"
                  key="end"
                  id="dropdown-button-drop-end"
                  drop="end"
                  title={<i className="bi bi-three-dots-vertical p-0"></i>}
                >
                  <>
                    {tabId === 0 || tabId === 1 ? (
                      <Dropdown.Item eventKey="1">
                        <button
                          role="link"
                          className="px-0 border-0 bg-transparent "
                        >
                          {/* <i className="fa fa-eye text-success mr-2 w-20px"></i> */}
                          View
                        </button>
                      </Dropdown.Item>
                    ) : null}
                    {tabId === 0 ? (
                      <>
                        <Dropdown.Item eventKey="2">
                          <div>
                            {/* <i className="fa fa-edit text-warning mr-2 w-20px"></i> */}
                            Re-Collect
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3">
                          <div>
                            {/* <i className="fa fa-edit text-warning mr-2 w-20px"></i> */}
                            Re-Draw
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="4">
                          <div>
                            {/* <i className="fa fa-edit text-warning mr-2 w-20px"></i> */}
                            Cancel
                          </div>
                        </Dropdown.Item>
                      </>
                    ) : null}
                    {tabId === 2 || tabId === 4 ? (
                      <>
                        <Dropdown.Item eventKey="5">
                          <div>
                            {/* <i className="fa fa-edit text-warning mr-2 w-20px"></i> */}
                            Edit
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="6">
                          <div>
                            {/* <i className="fa fa-edit text-warning mr-2 w-20px"></i> */}
                            Delete
                          </div>
                        </Dropdown.Item>
                      </>
                    ) : null}
                  </>
                </DropdownButton>
              </div>
            </TableCell>
          ) : null
        ) : (
          <div className="gap-2 d-flex">
            <button
              onClick={() => makeAPICALL()}
              className="btn btn-icon btn-sm fw-bold btn-table-save btn-icon-light h-32px w-32px fas-icon-20px"
            >
              <DoneIcon />
            </button>
            <button
              onClick={() => handleRowRemove()}
              className="btn btn-icon btn-sm fw-bold btn-table-cancel btn-icon-light h-32px w-32px fas-icon-20px"
            >
              <CrossIcon />
            </button>
          </div>
        )}
        {columnsHeader?.map((column: any, columnHeaderIndex: number) =>
          column?.isShowOnUi && !column?.isExpandData && column.isShow ? (
            item.rowStatus ? (
              <AddNewInputs
                item={item}
                column={column}
                columnHeaderIndex={columnHeaderIndex}
                inputFields={inputFields}
                handleInputsChange={handleChange}
              />
            ) : (
              <TableCell>{item?.[column?.columnKey] || ""}</TableCell>
            )
          ) : null
        )}
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} className="padding-0">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <PatientReqOrder
                patientId={item.PatientId}
                expandableColumnsHeader={expandableColumnsHeader}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Row;

// const containsHTMLUsingDOMParser = (str: string): boolean => {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(str, "text/html");

//   return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
// };
