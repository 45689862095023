import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserManagementService from "../../Services/UserManagement/UserManagementService";
import CheckBoxDuplicate from "../../Shared/Common/Input/CheckBoxDuplicate";
import Input2 from "../../Shared/Common/Input/Input2";
import Radio from "../../Shared/Common/Input/Radio";
import LoadButton from "../../Shared/Common/LoadButton";
import useForm from "../../Shared/hooks/useForm";
// import useLang from "Shared/hooks/useLanguage";
import BreadCrumbs from "../../Utils/Common/Breadcrumb";
import { GetElementsFromArray } from "../../Utils/Common/CommonMethods";
import { PortalTypeEnum } from "../../Utils/Common/Enums/Enums";
import { checkUncheckAllRolesAndPermissions } from "../../Utils/UserManagement/UserRoles";
import validate from "../../Utils/validate";
import { InitialState, setFormName } from "./InitialState";
import useLang from './../../Shared/hooks/useLanguage';
interface MyComponentProps {
  portalType: PortalTypeEnum; // Assuming this prop represents the portal type
}

const AddUserRoles: React.FC = () => {

  const { t } = useLang()

  const [userPermissions, setUserPermissions] = useState<any>([]);
  const [selectAll, setSelectAll] = useState<any>(false);

  const [rolesAndPermissionsLoading, setRolesAndPermissionsLoading] =
    useState(false);
  const { formData, setFormData, errors, changeHandler, setErrors }: any =
    useForm(InitialState, validate);
  let location = useLocation();

  useEffect(() => {
    getPreFilledUserName();
    if (formData?.roleType?.value) {
      getUSerRolesAndPermissions(formData.roleType.value);
    }
    // getPreFilledIds();
  }, [formData.roleType.value]);

  const getPreFilledUserName = () => {
    if (location?.state) {
      const roleName = setFormName(InitialState, location?.state);
      setFormData(roleName);
    }
  };
  // const getPreFilledIds = () => {
  //   // if (location?.state) {
  //     //   setUserPermissions(location.state.modules)
  //     //   console.log(userPermissions)
  //     // }
  //     if (location?.state) {
  //       const selectedModules = location.state.modules?.filter((module: any) => module.isSelected);
  //       debugger;
  //       setUserPermissions(selectedModules);
  //       console.log('UserPermissions', userPermissions)
  //     }
  // };

  const [isSubmitting, setisSubmitting] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // refetch();
  }, []);

  const loadData = () => {
    try {
      let response = UserManagementService.getAddUserRoles();
      return response;
    } catch (error) {
      return error;
    }
  };
  const { isLoading, data, refetch } = useQuery("userRolesData", loadData, {
    enabled: false,
  });

  //========== onchange for all select ==========
  const handleAllSelect = (checked: boolean) => {
    if (checked) {
      let permissionsChecked = checkUncheckAllRolesAndPermissions(
        userPermissions,
        true
      );
      setUserPermissions(permissionsChecked);
    } else {
      let permissionsUnChecked = checkUncheckAllRolesAndPermissions(
        userPermissions,
        false
      );
      setUserPermissions(permissionsUnChecked);
    }
    setSelectAll(!selectAll);
  };
  //========== onchange for single page select ==========
  const handleSinglePage = (
    checked: boolean,
    moduleId: number,
    pageId: number,
    pagePermissionId: number,
    moduleIndex: number,
    pageIndex: number,
    permissionIndex: number
  ) => {
    var CopieduserPermissions = [...userPermissions];
    CopieduserPermissions[moduleIndex].pages[pageIndex].permissions[
      permissionIndex
    ].isSelected = checked;
    if (checked) {
      CopieduserPermissions[moduleIndex].pages[pageIndex].isSelected = true;
      CopieduserPermissions[moduleIndex].isSelected = true;
    } else {
      let selectedItems: any = GetElementsFromArray(
        CopieduserPermissions[moduleIndex].pages[pageIndex].permissions,
        (item: any) => item.isSelected == true
      );
      if (selectedItems.length === 0) {
        CopieduserPermissions[moduleIndex].pages[pageIndex].isSelected = false;
        if (
          CopieduserPermissions[moduleIndex].pages.filter(
            (page: any) => page.isSelected
          ).length === 0
        ) {
          CopieduserPermissions[moduleIndex].isSelected = false;
        }
      }
    }
    setUserPermissions(CopieduserPermissions);
  };
  //========== onchange for all page select ==========
  const handleAllSinglePage = (
    checked: boolean,
    moduleId: number,
    pageId: number,
    moduleIndex: number,
    pageIndex: number
  ) => {
    let updatedUserPermissions = userPermissions.map((module: any) => {
      if (module.moduleId === moduleId) {
        let updatedModule = { ...module };
        updatedModule.isSelected = true;
        updatedModule.pages = updatedModule.pages.map((page: any) => {
          if (page.pageId === pageId) {
            let updatedPage = { ...page };
            updatedPage.isSelected = checked;
            updatedPage.permissions = updatedPage.permissions.map(
              (permession: any) => {
                return { ...permession, isSelected: checked };
              }
            );
            return updatedPage;
          }
          return page;
        });
        if (
          updatedModule.pages.length === 0 ||
          updatedModule.pages.every((page: any) => !page.isSelected)
        ) {
          updatedModule.isSelected = false;
        }
        return updatedModule;
      }
      return module;
    });
    setUserPermissions(updatedUserPermissions);
  };
  // ===== onchange for module select checkboxes
  const handleModuleCheckbox = (checked: boolean, moduleId: number) => {
    let updatedUserPermissions = userPermissions.map((module: any) => {
      if (module.moduleId === moduleId) {
        let updatedModule = { ...module };
        updatedModule.isSelected = checked;
        updatedModule.pages = updatedModule.pages.map((page: any) => {
          let updatedPage = { ...page };
          updatedPage.isSelected = checked;
          updatedPage.permissions = updatedPage.permissions.map(
            (permession: any) => {
              return { ...permession, isSelected: checked };
            }
          );
          return updatedPage;
        });
        return updatedModule;
      }
      return module;
    });
    setUserPermissions(updatedUserPermissions);
  };
  //========== Submit & save data ==========
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let size;
    let formErrors: any;
    formErrors = validate(formData, true);
    setErrors(formErrors);
    size = Object.keys(formErrors).length;
    if (size !== 0) {
      toast.error(t("Please fill the required fields!"));
      return;
    }
    const queryModel = {
      roleId: location?.state?.roleId ?? 0,
      roleName: formData.roleName.value,
      roleType: parseInt(formData.roleType.value, 10),
      // claimsIds: selectedBox?.claimsIds,
      modules: userPermissions,
    };
    const isAtLeastOneModuleSelected = queryModel.modules.some(
      (module: any) => module.isSelected
    );

    if (location?.state?.roleId === undefined) {
      if (isAtLeastOneModuleSelected) {
        setisSubmitting(true);
        await UserManagementService.saveUserRoles(queryModel)
          .then((res: AxiosResponse) => {
            if (res?.data?.data?.status === 200) {
              toast.success(res?.data?.data?.message);

              setisSubmitting(false);
              setTimeout(() => {
                navigate("/manageuserrole");
              }, 1000);
            }
          })
          .catch((err?: AxiosError) => {
            toast.error(t("error"));
            setisSubmitting(false);
          });
      } else {
        toast.error(t("Select at least one role"));
      }
    }
    if (location?.state?.roleId > 0) {
      if (isAtLeastOneModuleSelected) {
        setisSubmitting(true);
        await UserManagementService.saveUserRoles(queryModel)
          .then((res: AxiosResponse) => {
            if (res?.data?.data?.status === 200) {
              toast.success(res?.data?.data?.message);
              setisSubmitting(false);
              setTimeout(() => {
                navigate("/manageuserrole");
              }, 1000);
            }
          })
          .catch((err?: AxiosError) => {
            toast.error(t("error"));
            setisSubmitting(false);
          });
      } else {
        toast.error(t("Select at least one role"));
      }
    }
  };
  //========== new api to get userRoles And Permisisions ==========
  const getUSerRolesAndPermissions = async (ptype: any) => {
    let response;
    try {
      setRolesAndPermissionsLoading(true);
      if (location?.state) {
        if (location.state.modules === null) {
          response = await UserManagementService.getAllUserRolesAndPermissions(
            ptype
          );
          setUserPermissions(response?.data?.data);
        } else {
          const selectedModules = location.state.modules;
          response = await UserManagementService.getAllUserRolesAndPermissions(
            ptype
          );

          // Update isSelected property in response based on selectedModules
          const updatedResponse = response?.data?.data.map(
            (moduleFromAPI: any) => {
              const selectedModule = selectedModules?.find(
                (selectedModule: any) =>
                  selectedModule.moduleId === moduleFromAPI.moduleId
              );

              if (selectedModule) {
                return {
                  ...moduleFromAPI,
                  isSelected: selectedModule.isSelected,
                  pages: moduleFromAPI.pages.map((pageFromAPI: any) => {
                    const selectedPage = selectedModule.pages.find(
                      (selectedPage: any) =>
                        selectedPage.pageId === pageFromAPI.pageId
                    );

                    if (selectedPage) {
                      return {
                        ...pageFromAPI,
                        isSelected: selectedPage.isSelected,
                        permissions: pageFromAPI.permissions.map(
                          (permissionFromAPI: any) => {
                            const selectedPermission =
                              selectedPage.permissions.find(
                                (selectedPermission: any) =>
                                  selectedPermission.pagePermissionId ===
                                  permissionFromAPI.pagePermissionId
                              );

                            if (selectedPermission) {
                              return {
                                ...permissionFromAPI,
                                isSelected: selectedPermission.isSelected,
                              };
                            }

                            return permissionFromAPI;
                          }
                        ),
                      };
                    }

                    return pageFromAPI;
                  }),
                };
              }

              return moduleFromAPI;
            }
          );

          setUserPermissions(updatedResponse);
        }
      } else {
        response = await UserManagementService.getAllUserRolesAndPermissions(
          ptype
        );
        setUserPermissions(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRolesAndPermissionsLoading(false);
    }
  };
  const facilityId = useSelector(
    (state: any) => state?.Reducer?.facilityData?.facilityId
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (location?.state?.roleId > 0) return false;
        else {
          const response =
            await UserManagementService.getAllUserRolesAndPermissions(2);
          facilityId && setUserPermissions(response?.data?.data);
        }
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching user roles and permissions:", error);
      }
    };

    fetchData(); // Call the async function immediately
  }, []);

  return (
    <>
      <div className="app-toolbar py-3 py-lg-6">
        <div className="app-container container-fluid d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center">
          <BreadCrumbs />
        </div>
      </div>
      <div className="app-container container-fluid">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <form onSubmit={handleSubmit}>
            <div className="card shadow-sm mb-3 rounded">
              <div className="card-header d-flex justify-content-sm-between justify-content-center minh-42px align-items-center">
                <h5 className="m-0 ">
                  {location?.state?.roleId
                    ? t("Edit User Roles")
                    : t("Add User Roles")}
                </h5>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                  <Link
                    to="/manageuserrole"
                    className="fw-bold btn btn-secondary btn-sm btn-secondary--icon rounded"
                  >
                    <span>
                      <span>{t("cancel")}</span>
                    </span>
                  </Link>
                  {location?.state?.roleId > 0 ? (
                    <LoadButton
                      className="btn btn-sm fw-bold btn-primary"
                      loading={isSubmitting}
                      btnText={t("Update")}
                      loadingText={t("Updating")}
                    />
                  ) : (
                    <LoadButton
                      className="btn btn-sm fw-bold btn-primary"
                      loading={isSubmitting}
                      btnText={t("Save")}
                      loadingText={t("Saving")}
                    />
                  )}
                </div>
              </div>
              <div className="card-body py-4">
                <Input2
                  type="text"
                  name="roleName"
                  label={t("User Role Name")}
                  className="form-control bg-transparent"
                  placeholder={t("User Role Name")}
                  onChange={changeHandler}
                  parentDivClassName="col-12"
                  value={formData?.roleName?.value}
                  error={errors?.roleName}
                  disabled={location?.state?.roleId > 0 ? true : false}
                />
                <div className="fv-row mb-4">
                  <Radio
                    label={t("Role Type:")}
                    name="roleType"
                    onChange={(event) => {
                      setSelectAll(false);
                      changeHandler(event);
                    }}
                    choices={[
                      {
                        id: "Admin",
                        label: t("Admin"),
                        value: String(PortalTypeEnum.Admin),
                      },
                      {
                        id: "Facility",
                        label: t("Facility"),
                        value: String(PortalTypeEnum.Facility),
                      },
                      {
                        id: "Sales",
                        label: t("Sales"),
                        value: String(PortalTypeEnum.Sales),
                      },
                    ]}
                    error={errors?.roleType}
                    // checked={
                    //   location?.state?.roleId
                    //     ? formData?.roleType?.value
                    //     : facilityId
                    //     ? 2
                    //     : formData?.roleType?.value
                    // }
                    checked={formData?.roleType?.value.toString()}
                    // setformData2={setformData2}
                    disabled={
                      location?.state?.roleId || facilityId ? true : false
                    }
                  />
                </div>
                <hr />
                <div style={{ width: "fit-content" }}>
                  <CheckBoxDuplicate
                    id="all001"
                    name="allcheckbox"
                    checked={selectAll}
                    onChange={(e) => handleAllSelect(e.target.checked)}
                    label={t("All")}
                    labelClassName="form-check form-check-sm form-check-solid col-12 mb-2 mt-2"
                    spanClassName="form-check-label ms-1 fw-600 fs-7"
                    loading={isLoading}
                    parentDivClassName="col-12"
                  />
                </div>

                {rolesAndPermissionsLoading ? (
                  <span>{t("Loading...")}</span>
                ) : (
                  userPermissions.map(
                    (
                      { moduleId, moduleName, pages, isSelected }: any,
                      moduleIndex: number
                    ) => (
                      <>
                        <div className="mb-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                          <div className="modal-content">
                            <div className="form__group form__group--checkbox bg-light px-2 py-1">
                              <label
                                htmlFor="kt_roles_select_all"
                                className="form-check form-check-custom"
                              >
                                <input
                                  className="form-check-input w-15px h-15px rounded-01 "
                                  type="checkbox"
                                  value=""
                                  id={`module_checkbox_${moduleId}`}
                                  onChange={(e) =>
                                    handleModuleCheckbox(
                                      e.target.checked,
                                      moduleId
                                    )
                                  }
                                  checked={isSelected}
                                />
                                <span className="form-check-label ms-1 fw-600 fs-7">
                                  {moduleName}
                                  <i className="bi bi-arrow-right fs-8 text-primary ms-1"></i>
                                </span>
                              </label>
                            </div>

                            <div className="modal-body ">
                              <form
                                id={`kt_modal_add_role_form_${moduleId}`}
                                className="form"
                                action="#"
                              >
                                <div
                                  className="d-flex flex-column scroll-y me-n7 pe-7"
                                  id="kt_modal_add_role_scroll"
                                  data-kt-scroll="true"
                                  data-kt-scroll-activate="{default: false, lg: true}"
                                  data-kt-scroll-max-height="auto"
                                  data-kt-scroll-dependencies="#kt_modal_add_role_header"
                                  data-kt-scroll-wrappers="#kt_modal_add_role_scroll"
                                  data-kt-scroll-offset="300px"
                                >
                                  {pages.map(
                                    (
                                      {
                                        pageName,
                                        pageId,
                                        permissions,
                                        isSelected,
                                      }: any,
                                      pageIndex: number
                                    ) => (
                                      <>
                                        <div className="row py-2 border-bottom mx-md-9 mx-5">
                                          <div className="text-gray-800 col-xxl-2 col-xl-3 col-lg-3 col-12 border-0">
                                            <label
                                              htmlFor={`page_checkbox_${pageId}`}
                                              className="form-check form-check-custom align-items-start"
                                            >
                                              <input
                                                className="form-check-input w-15px h-15px rounded-01 "
                                                type="checkbox"
                                                value=""
                                                id={`page_checkbox_${pageId}`}
                                                checked={isSelected}
                                                onChange={(e) =>
                                                  handleAllSinglePage(
                                                    e.target.checked,
                                                    moduleId,
                                                    pageId,
                                                    moduleIndex,
                                                    pageIndex
                                                  )
                                                }
                                              />
                                              <span className="form-check-label text-primary ms-1 fw-600 fs-7 ">
                                                {pageName}
                                                <i className="bi bi-chevron-double-right fs-8 text-primary ms-1"></i>
                                              </span>
                                            </label>
                                          </div>
                                          <div className="col-xxl-10 col-xl-9 col-lg-9">
                                            <div className="row">
                                              {permissions.map(
                                                (
                                                  {
                                                    permissionName,
                                                    isSelected,
                                                    pagePermissionId,
                                                  }: any,
                                                  permissionIndex: number
                                                ) => (
                                                  <>
                                                    <div className="col-xl-2 col-lg-4 col-md-3 col-sm-4 col-6 border-0">
                                                      <label
                                                        htmlFor={`single_page_checkbox_${pagePermissionId}`}
                                                        className="form-check form-check-custom align-items-start"
                                                      >
                                                        <input
                                                          className="form-check-input w-15px h-15px rounded-01"
                                                          type="checkbox"
                                                          value=""
                                                          id={`single_page_checkbox_${pagePermissionId}`}
                                                          onChange={(e) =>
                                                            handleSinglePage(
                                                              e.target.checked,
                                                              moduleId,
                                                              pageId,
                                                              pagePermissionId,
                                                              moduleIndex,
                                                              pageIndex,
                                                              permissionIndex
                                                            )
                                                          }
                                                          checked={isSelected}
                                                        />
                                                        <span className="form-check-label text-gray-900 ms-1 fw-600 fs-9">
                                                          {permissionName}
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUserRoles;
