import { useEffect, useRef, useState } from "react";
import { IPropsGroupSelect } from "../../Interface/SingleRequisition";
import Checkbox from "../Common/Input/Checkbox";
import Input from "../Common/Input/Input";
import { AxiosResponse } from "axios";
import { t } from "i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setRequisitionData,
  setSelectedRequisitionData,
} from "../../Redux/Actions/Pages/Requisition";
import RequisitionType from "../../Services/Requisition/RequisitionTypeService";
import { assignFormValues } from "../../Utils/Auth";
import {
  collectorNameOptionsAdd,
  extractDropDownSelectedValue,
  FacilityAdd,
} from "../../Utils/Common/Requisition";
import FacilityDropdown from "./FacilityDropdown";
import Popup from "./Popup";
import ProviderDropdown from "./ProviderDropdown";

const GroupedSelect: React.FC<IPropsGroupSelect> = (
  {
    id,
    fields,
    isShown,
    setIsShown,
    inputs,
    dependenceyControls,
    index,
    errorFocussedInput,
    setInputs,
    RequsitionData,
    setInfectiousData,
    setPhysicianId,
    physicianId,
    ValidationCheckOnClick,
    props,
  },
  ownProps: any
) => {
  const _adminType = useSelector(
    (state: any) =>
      state?.Reducer?.selectedTenantInfo?.infomationOfLoggedUser?.portalType
  );
  const user = useSelector((state: any) => state?.Reducer);

  useEffect(() => {
    props.Inputs[props.index]?.fields?.forEach((field: any) => {
      if (field?.systemFieldName === "PhysicianID") {
        setPhysicianList(field?.options);
      }
    });
  }, []);

  const ref = useRef<any>(null);
  const [selectedDropDownValue, setSelectedDropDownValue] = useState<any>({});
  const [physicianList, setPhysicianList] = useState([]);

  const [showhide, setshowhide] = useState<boolean>(true);
  const [open, setOpen] = useState<any>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [facilities, setFacilities] = useState<any>([]);
  useEffect(() => {
    getPreFilledFormValuesToEdit();
  }, []);
  const LoadRequisitionSection = (obj: any) => {
    RequisitionType?.LoadReqSectionByFacilityIDandInsuranceId(obj)
      .then((res: AxiosResponse) => {
        res.data.forEach((requisitionNameLevel: any) => {
          requisitionNameLevel.sections.forEach((sectionsLevel: any) => {
            sectionsLevel.fields.forEach((fieldsLevel: any) => {
              fieldsLevel.singleRequsition = true;
            });
          });
        });
        setInfectiousData(res.data);
        if (location?.state?.reqId) {
          let objToSend: any;
          let ReqIdArray: any = [];
          let ReqNameArray: any = [];
          res.data.map((item: any) => {
            ReqIdArray.push(item?.reqId);
            ReqNameArray.push(item?.requistionName);
          });
          objToSend = {
            requsitionId: ReqIdArray,
            requisitionName: ReqNameArray,
          };
          dispatch(setSelectedRequisitionData(objToSend));
        }
        dispatch(
          setRequisitionData({
            reqRequestData: res?.data,
          })
        );
        ref.current.scrollIntoView({ behavior: "smooth" });
      })
      .catch((err: any) => {
        console.trace("err");
      });
  };
  const getPreFilledFormValuesToEdit = () => {
    let selectedFacility = extractDropDownSelectedValue(fields, "Facility");
    let selectedProvider = extractDropDownSelectedValue(fields, "Physician");
    console.log(selectedFacility, "selectedFacility");
    onFacilitySelect(selectedFacility);
    onProviderSelect(selectedProvider);
  };
  // const onFacilitySelect = (e: any) => {
  //   if (!e?.value) return;
  //   RequisitionType.getProvidersList(e.value)
  //     .then((res: any) => {
  //       setPhysicianList(res?.data);
  //     })
  //     .catch((err: any) => {
  //       console.trace(err);
  //     });
  //   getCollectorNameList(e?.value);
  //   dispatch(
  //     setRequisitionData({
  //       facilityId: e?.value,
  //     })
  //   );
  //   assignFormValues(
  //     inputs,
  //     dependenceyControls,
  //     index,
  //     undefined,
  //     0,
  //     e.value,
  //     false,
  //     false,
  //     false,
  //     undefined,
  //     undefined,
  //     undefined,
  //     e.label,
  //     setInputs
  //   );
  //   localStorage.setItem("facilityID", e?.value);
  //   if (location?.state?.reqId) {
  //     let obj = {
  //       facilityId: localStorage.getItem("facilityID"),
  //       insuranceTypeId: localStorage.getItem("insurnceID"),
  //       pageId: 6,
  //       requisitionId: location?.state?.reqId,
  //       requisitionOrderId: location.state.orderid,
  //     };
  //     LoadRequisitionSection(obj);
  //   }
  //   setSelectedDropDownValue((preVal: any) => {
  //     return {
  //       ...preVal,
  //       selectedFacility: e,
  //       selectedProvider: "",
  //     };
  //   });
  // };
  const ProviderLookup = (e: any) => {
    console.log("Hi", e);
    RequisitionType.getProvidersList(e)
      .then((res: any) => {
        setPhysicianList(res?.data);
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };
  const [facilitySelect, setFacilitySelect] = useState<any>(false);
  const onFacilitySelect = (e: any) => {
    if (!e?.value) return;
    setFacilitySelect(true);
    ProviderLookup(e.value);
    getCollectorNameList(e?.value);
    dispatch(
      setRequisitionData({
        facilityId: e?.value,
      })
    );
    let newInputs = assignFormValues(
      inputs,
      index,
      undefined,
      0,
      e.value,
      false,
      false,
      false,
      undefined,
      undefined,
      undefined,
      e.label,
      setInputs
    );
    if (!location?.state?.reqId) {
      newInputs?.then((res) => {
        setInputs(res);
      });
    }
    //if (e?.value) ValidationCheckOnClick();
    localStorage.setItem("facilityID", e?.value);
    let obj = {
      facilityId: e?.value,
      insuranceTypeId: localStorage.getItem("insurnceID"),
      pageId: 6,
      requisitionId: location?.state?.reqId,
      requisitionOrderId: location?.state?.orderid,
    };
    LoadRequisitionSection(obj);
    if (location?.state?.reqId) {
      LoadRequisitionSection(obj);
    }
    setSelectedDropDownValue((preVal: any) => {
      return {
        ...preVal,
        selectedFacility: e,
        // selectedProvider: "",
      };
    });
  };
  const location = useLocation();
  const onProviderSelect = async (e: any) => {
    if (!e?.value) return;
    if (!facilitySelect && _adminType !== 2 && !location.state.reqId) {
      const response = await RequisitionType.GetFacilityByProviderId(e?.value);
      setFacilities(response?.data?.facilities);
      const FacilityInput = FacilityAdd(inputs, response?.data?.facilities);
      let FacilityInputCopy: any = [...FacilityInput];
      setInputs(FacilityInputCopy);
    }
    let newInputs = assignFormValues(
      inputs,
      index,
      undefined,
      1,
      e.value,
      false,
      false,
      false,
      undefined,
      undefined,
      undefined,
      e.label,
      setInputs
    );

    if (!location?.state?.reqId) {
      newInputs?.then((res) => {
        setInputs(res);
        props?.setPhysicianChange(!props?.physicianChange);
      });
    }
    //if (e?.value) ValidationCheckOnClick();
    sessionStorage.setItem("PhysicianID", e?.value);
    setSelectedDropDownValue((preVal: any) => {
      return {
        ...preVal,
        selectedProvider: e,
      };
    });
  };

  const getCollectorNameList = async (facilityId: string) => {
    RequisitionType.getCollectorsList(facilityId)
      .then((res: any) => {
        const InputsWithCollectorNameOption = collectorNameOptionsAdd(
          inputs,
          facilityId,
          res?.data
        );
        let InputsWithCollectorNameOptionCopy = [
          ...InputsWithCollectorNameOption,
        ];
        setInputs(InputsWithCollectorNameOptionCopy);
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };

  return (
    <>
      {fields?.map((items: any) => (
        <>
          <div className={`${items?.displayType} mb-4`}>
            {items?.uiType === "DropDown" && items?.visible ? (
              <>
                {!showhide && items?.systemFieldName === "PhysicianID" ? (
                  <label className="mb-2 fw-500"> </label>
                ) : (
                  <label
                    className={
                      items?.required &&
                      items.systemFieldName === "FacilityID" &&
                      _adminType == 2
                        ? ""
                        : "required mb-2 fw-500"
                    }
                  >
                    {items.systemFieldName === "FacilityID" && _adminType == 2
                      ? null
                      : t(items?.displayFieldName)}
                  </label>
                )}
                <div className="col-lg-12">
                  {items?.systemFieldName === "PhysicianID" ? (
                    <>
                      <div className="row">
                        {(showhide &&
                          items?.systemFieldName === "PhysicianID") ||
                        fields?.length === 2 ? (
                          <>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                              <ProviderDropdown
                                physicianArr={physicianList}
                                onProviderSelect={onProviderSelect}
                                selectedProviders={
                                  selectedDropDownValue?.selectedProvider
                                }
                                name={items?.systemFieldName}
                                errorFocussedInput={errorFocussedInput}
                                setPhysicianList={setPhysicianList}
                                setPhysicianId={setPhysicianId}
                              />
                            </div>

                            <div
                              className={`col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12`}
                            >
                              <button
                                type="button"
                                className="btn btn-light-primary btn-sm px-4 p-2 py-3 mt-2 mt-sm-0"
                                onClick={handleOpen}
                              >
                                <i
                                  className="bi bi-plus"
                                  style={{ fontSize: "20px" }}
                                ></i>
                                {t("Add New Provider")}
                              </button>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-lg-12">
                  {items?.systemFieldName === "FacilityID" ? (
                    <FacilityDropdown
                      facilityList={items.options}
                      onFacilitySelect={onFacilitySelect}
                      selectedFacilities={
                        selectedDropDownValue?.selectedFacility
                      }
                      name={items?.systemFieldName}
                      errorFocussedInput={errorFocussedInput}
                      setSelectedDropDownValue={setSelectedDropDownValue}
                      _adminType={_adminType}
                    />
                  ) : null}
                </div>
                {items.systemFieldName === "FacilityID" && _adminType == 2
                  ? null
                  : items.enableRule && (
                      <div className="form__error">
                        <span>{t(items.enableRule)}</span>
                      </div>
                    )}
              </>
            ) : null}
            {(items?.uiType === "TextBox" || items?.uiType === "TextArea") &&
            items?.visible ? (
              <>
                <label className="required mb-2 fw-500">
                  {t(items?.displayFieldName)}
                </label>
                <Input
                  label={items?.displayFieldName}
                  onChange={(e: any) => e.target.value}
                  required={items?.required}
                  // ArrayReqId={ArrayReqId}
                />
              </>
            ) : null}
            {items?.uiType === "CheckBox" && items?.visible ? (
              <>
                <Checkbox
                  grandParentClassName="col-lg-12 mt-9"
                  spanClassName="ms-3"
                  labelClassName="mb-2 mr-2 col-lg-12"
                  label={items?.displayFieldName}
                  onChange={(e: any) => setshowhide(!e.target.checked)}
                />
              </>
            ) : null}
          </div>
        </>
      ))}
      {/* modalstart */}
      <Popup
        open={open}
        handleClose={handleClose}
        isShown={isShown}
        index={index}
        setIsShown={setIsShown}
        inputs={inputs}
        setInputs={setInputs}
        setSelectedDropDownValue={setSelectedDropDownValue}
        selectedDropDownValue={selectedDropDownValue}
        dependenceyControls={dependenceyControls}
        setPhysicianList={setPhysicianList}
        physicianList={physicianList}
        onFacilitySelect={onFacilitySelect}
        fields={fields}
        getCollectorNameList={getCollectorNameList}
        ProviderLookup={ProviderLookup}
      />
    </>
  );
};
function mapStateToProps(state: any, ownProps: any) {
  return { RequsitionData: state };
}
export default connect(mapStateToProps)(GroupedSelect);
