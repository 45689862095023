import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import FacilityService from "../../../Services/FacilityService/FacilityService";
import useLang from "./../../hooks/useLanguage";

interface FileDetail {
  fileUrl: string;
  fileName: string;
  controlId: string;
}

const Document: React.FC<any> = (props) => {
  const { t } = useLang();
  const [uploading, setUploading] = useState(false);
  const [imagesArray, setImagesArray] = useState<FileDetail[]>(
    JSON.parse(props.defaultValue || "[]")
  );
  const location = useLocation();
  const uniqueControlId = useMemo(
    () => `${props.controlId}-${Math.random().toString(36).substr(2, 9)}`,
    [props.controlId]
  );

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (imagesArray.some((image) => image.fileName === file.name)) {
        toast.error(t("File with the same name already exists"));
        return;
      }
      if (!["application/pdf", "image/jpeg", "image/png"].includes(file.type)) {
        toast.error(t("You can only upload PDF, JPG, PNG file types"));
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      setUploading(true);
      try {
        const res = await FacilityService.UploadResultsToBlob(formData);
        const newFileDetail = {
          fileUrl: res.data.Data,
          fileName: file.name,
          controlId: uniqueControlId,
        };
        setImagesArray((prev) => [...prev, newFileDetail]);
      } catch (err) {
        console.error(err);
      } finally {
        setUploading(false);
        e.target.value = "";
      }
    }
  };

  const handleImageDeselect = (fileName: string) => {
    setImagesArray((prev) =>
      prev.filter((image) => image.fileName !== fileName)
    );
  };

  const renderedImages = imagesArray.map((file, index) => (
    <div key={index} className="border bg-light-secondary rounded p-2 my-3">
      <div className="d-flex justify-content-between">
        <div className="text-dark-65">{file.fileName}</div>
        <div
          onClick={() => handleImageDeselect(file.fileName)}
          style={{ cursor: "pointer" }}
        >
          &#x2716;
        </div>
      </div>
    </div>
  ));

  return (
    <div className="mt-3">
      <input
        type="file"
        onChange={handleUpload}
        multiple
        id="upload-file"
        className="d-none"
      />
      <label htmlFor="upload-file" className="dropzone">
        <div className="dz-message needsclick">
          <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
          <div className="ms-4">
            <h3 className="fs-5 fw-bold text-gray-900 mb-1">
              {t("Drop files here or click to upload.")}
            </h3>
            <span className="fs-7 text-gray-400">
              {t("Upload up to 10 files")}
            </span>
          </div>
        </div>
      </label>
      {uploading ? <h1>{t("Uploading...")}</h1> : null}
      {renderedImages}
    </div>
  );
};

export default Document;
