// import { useEffect, useRef } from "react";
// import MuiSkeleton from "../MuiSkeleton";
// import useLang from "./../../hooks/useLanguage";

// const TimeInput: React.FC<any> = (props) => {
//   const { t } = useLang();
//   const inputElement = useRef<HTMLInputElement>(null);

//   useEffect(() => {
//     if (inputElement.current) {
//       inputElement.current.value = props.value;
//     }
//   }, [props.value]);

//   return (
//     <div
//       className={
//         props?.parentDivClassName
//           ? `${props?.parentDivClassName} mb-4`
//           : "col-lg-6 col-md-6 col-sm-12 mb-4"
//       }
//     >
//       <label
//         className={props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"}
//         htmlFor={props.id}
//       >
//         {t(props.label)}
//       </label>

//       {props?.loading ? (
//         <MuiSkeleton />
//       ) : (
//         <input
//           type="time"
//           value={props.value}
//           name={props.name}
//           id={props.id}
//           onChange={props.onChange}
//           className="form-control bg-transparent"
//           required={props.required}
//           ref={inputElement}
//         />
//       )}
//       {props.error && (
//         <div className="form__error">
//           <span>{t(props.error)}</span>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TimeInput;
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MuiSkeleton from "../MuiSkeleton";
import { memo, useEffect, useRef, useState } from "react";
import { CrossIcon } from "../../Icons";
import useLang from "../../hooks/useLanguage";

const TimePickerr = (props: any) => {
  const { t } = useLang();
  const [open, setOpen] = useState(false);
  const [displayTime, setDisplayTime] = useState(""); // To store formatted time
  const refTimePicker = useRef(null);
  const inputElement = useRef<HTMLInputElement>(null);

  // Convert time to 12-hour format
  const formatTime = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    const suffix = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${suffix}`;
  };

  useEffect(() => {
    if (props.value) {
      setDisplayTime(formatTime(props.value)); // Update the formatted time
    }
  }, [props.value]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value;
    setDisplayTime(formatTime(newTime)); // Format and update displayed time
    if (props.onChange) {
      props.onChange(event); // Pass event back to parent if `onChange` is defined
    }
  };

  const style = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  return (
    <div
      className={
        props?.parentDivClassName
          ? `${props?.parentDivClassName} mb-4`
          : "col-lg-6 col-md-6 col-sm-12 mb-4"
      }
    >
      <label
        className={props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"}
        htmlFor={props.id}
      >
        {t(props.label)}
      </label>
      {props?.loading ? (
        <MuiSkeleton />
      ) : (
        <input
          onClick={handleOpen}
          readOnly
          placeholder={props.label}
          name={props.name}
          id={props.id}
          value={displayTime} // Display formatted time
          ref={inputElement}
          required={props.required}
          className="form-control bg-transparent"
        />
      )}
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div ref={refTimePicker}>
            {open && (
              <>
                <Box
                  className="icon-container"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>{t(props.label)}</span>
                  <span onClick={handleClose} className="cursor-pointer">
                    <CrossIcon />
                  </span>
                </Box>
                <input
                  type="time"
                  value={props.value}
                  name={props.name}
                  id={props.id}
                  onChange={handleTimeChange} // Update time on change
                  className="form-control bg-transparent"
                />
              </>
            )}
          </div>
          <div style={{ textAlign: "end", padding: "0% 2%" }} className="mt-5">
            <button
              className="btn btn-sm fw-500 btn-primary"
              onClick={handleClose}
            >
              {t("Submit")}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default memo(TimePickerr);
