// import { useEffect, useRef, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { assignFormValues } from "../../../Utils/Auth";
// import useLang from "./../../hooks/useLanguage";

// const PanelsCheckboxSpecimenSource = (props: any) => {
//   const { t } = useLang();
//   const location = useLocation();
//   function FindIndex(arr: any[], rid: any) {
//     return arr.findIndex((i: any) => i.reqId === rid);
//   }
//   const convertPrefilledIcdDataToJson = () => {
//     let inputValue: any[] = [];
//     props.panels.map((inner: any) =>
//       inner.specimenSourceOption.map(
//         (option: any) => option.isSelected && inputValue.push(option)
//       )
//     );
//     let newInputs = assignFormValues(
//       props?.Inputs,
//       props?.index,
//       props?.depControlIndex,
//       props?.fieldIndex,
//       inputValue,
//       props?.isDependency,
//       props?.repeatFieldSection,
//       props?.isDependencyRepeatFields,
//       props?.repeatFieldIndex,
//       props?.repeatDependencySectionIndex,
//       props?.repeatDepFieldIndex,
//       undefined,
//       props?.setInputs
//     );
//     newInputs.then((res) => {
//       let infectiousDataCopy = JSON.parse(
//         JSON.stringify(props?.infectiousData)
//       );
//       infectiousDataCopy[
//         FindIndex(props?.infectiousData, props.ArrayReqId)
//       ].sections = res;
//       props?.setInfectiousData &&
//         props?.setInfectiousData([...infectiousDataCopy]);
//     });
//   };
//   useEffect(() => {
//     if (location?.state?.reqId) {
//       convertPrefilledIcdDataToJson();
//     }
//   }, [props.defaultValue]);
//   let inputValue: any[] = [];

//   const handleChange = (
//     e: any,
//     options: any,
//     checked: boolean,
//     panelName: string
//   ) => {
//     debugger;
//     // Clone current state of inputs
//     const panelIndex = props.panels.findIndex(
//       (panel: any) => panel.panelID === options.panelID
//     );

//     if (panelIndex === -1) {
//       console.warn(
//         "Panel not found for the provided panelID:",
//         options.panelID
//       );
//       return;
//     }

//     // Find the index of the specimen option based on specimenTypeID
//     const specimenIndex = props.panels[
//       panelIndex
//     ].specimenSourceOption.findIndex(
//       (specimen: any) => specimen.specimenTypeID === options.specimenTypeID
//     );

//     if (specimenIndex === -1) {
//       console.warn(
//         "Specimen not found for the provided specimenTypeID:",
//         options.specimenTypeID
//       );
//       return;
//     }

//     // Update the isSelected flag for the found specimen option
//     props.panels[panelIndex].specimenSourceOption[specimenIndex].isSelected =
//       e.target.checked;

//     if (checked) {
//       // Add the checked specimen source to inputValue
//       let obj = {
//         panelID:
//           props.panels[panelIndex].specimenSourceOption[specimenIndex].panelID,
//         specimenPreFix:
//           props.panels[panelIndex].specimenSourceOption[specimenIndex]
//             .specimenPreFix,
//         specimenType:
//           props.panels[panelIndex].specimenSourceOption[specimenIndex]
//             .specimenType,
//         specimenTypeID:
//           props.panels[panelIndex].specimenSourceOption[specimenIndex]
//             .specimenTypeID,
//         panelName: panelName,
//         isSelected: checked,
//       };
//       inputValue = [...inputValue, obj];
//     } else {
//       // Remove the unchecked specimen source from inputValue
//       inputValue = inputValue.filter(
//         (item: any) =>
//           item.specimenTypeID !==
//           props.panels[panelIndex].specimenSourceOption[specimenIndex]
//             .specimenTypeID
//       );
//     }

//     // Update inputs with the modified array
//     let newInputs = assignFormValues(
//       props?.Inputs,
//       props?.index,
//       props?.depControlIndex,
//       props?.fieldIndex,
//       inputValue,
//       props?.isDependency,
//       props?.repeatFieldSection,
//       props?.isDependencyRepeatFields,
//       props?.repeatFieldIndex,
//       props?.repeatDependencySectionIndex,
//       props?.repeatDepFieldIndex,
//       undefined,
//       props?.setInputs
//     );

//     newInputs.then((res) => {
//       let infectiousDataCopy = JSON.parse(
//         JSON.stringify(props?.infectiousData)
//       );
//       infectiousDataCopy[
//         FindIndex(props?.infectiousData, props.ArrayReqId)
//       ].sections = res;
//       props?.setInfectiousData &&
//         props?.setInfectiousData([...infectiousDataCopy]);
//     });
//   };

//   const divElement = useRef<HTMLDivElement | null>(null);
//   useEffect(() => {
//     if (props.error && divElement.current) {
//       divElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
//     }
//   }, [props.error]);
//   return (
//     <>
//       {props.error && (
//         <div className="form__error">
//           <span>{t(props.error)}</span>
//         </div>
//       )}
//       <div id={props?.name} ref={divElement} tabIndex={-1}></div>
//       <div className={props?.sectionDisplayType}>
//         <div className="d-flex flex-wrap ">
//           {props?.panels?.map(
//             (panels: any) =>
//               panels?.isVisible && (
//                 <div
//                   className={`card shadow-sm mb-3 rounded border border-warning ${props?.displayType}`}
//                   key={panels.panelID}
//                 >
//                   <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning">
//                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
//                       <h6>{panels?.panelName}</h6>
//                     </div>
//                   </div>
//                   <div className="card-body py-md-4 py-3">
//                     <div className="row">
//                       {panels?.specimenSourceOption?.map(
//                         (options: any, index: any) => (
//                           <div
//                             className="col-xl-6 col-lg-6 col-md-6 col-sm-6"
//                             key={options.specimenPreFix}
//                           >
//                             <label className="form-check form-check-inline form-check-solid m-0 mb-2">
//                               <input
//                                 className="form-check-input h-20px w-20px"
//                                 type="checkbox"
//                                 id={props.id}
//                                 name={props.label}
//                                 onChange={(e: any) => {
//                                   handleChange(
//                                     e,
//                                     options,
//                                     e.target.checked,
//                                     panels.panelName
//                                   );
//                                 }}
//                                 checked={options.isSelected}
//                                 disabled={props?.disabled}
//                               />
//                               {options.specimenType}
//                             </label>
//                           </div>
//                         )
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               )
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default PanelsCheckboxSpecimenSource;
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { assignFormValues } from "../../../Utils/Auth";
import useLang from "./../../hooks/useLanguage";

const PanelsCheckboxSpecimenSource = (props: any) => {
  const { t } = useLang();
  const location = useLocation();
  const divElement = useRef<HTMLDivElement | null>(null);

  const [inputValue, setInputValue] = useState<any[]>([]);

  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }

  const convertPrefilledIcdDataToJson = () => {
    let newInputValue: any[] = [];
    props.panels.forEach((panel: any) =>
      panel.specimenSourceOption.forEach((option: any) => {
        if (option.isSelected) {
          newInputValue.push(option);
        }
      })
    );

    setInputValue(newInputValue);

    let newInputs = assignFormValues(
      props?.Inputs,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      newInputValue,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    newInputs.then((res) => {
      let infectiousDataCopy = JSON.parse(
        JSON.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData &&
        props?.setInfectiousData([...infectiousDataCopy]);
    });
  };

  const handleChange = (
    e: any,
    options: any,
    checked: boolean,
    panelName: string
  ) => {
    const panelIndex = props.panels.findIndex(
      (panel: any) => panel.panelID === options.panelID
    );

    if (panelIndex === -1) {
      console.warn(
        "Panel not found for the provided panelID:",
        options.panelID
      );
      return;
    }

    const specimenIndex = props.panels[
      panelIndex
    ].specimenSourceOption.findIndex(
      (specimen: any) => specimen.specimenTypeID === options.specimenTypeID
    );

    if (specimenIndex === -1) {
      console.warn(
        "Specimen not found for the provided specimenTypeID:",
        options.specimenTypeID
      );
      return;
    }

    props.panels[panelIndex].specimenSourceOption[specimenIndex].isSelected =
      e.target.checked;

    let updatedInputValue;
    if (checked) {
      // Add the checked specimen
      const newObj = {
        panelID:
          props.panels[panelIndex].specimenSourceOption[specimenIndex].panelID,
        specimenPreFix:
          props.panels[panelIndex].specimenSourceOption[specimenIndex]
            .specimenPreFix,
        specimenType:
          props.panels[panelIndex].specimenSourceOption[specimenIndex]
            .specimenType,
        specimenTypeID:
          props.panels[panelIndex].specimenSourceOption[specimenIndex]
            .specimenTypeID,
        panelName,
        isSelected: checked,
      };

      updatedInputValue = [...inputValue, newObj];
    } else {
      // Remove the unchecked specimen
      updatedInputValue = inputValue.filter(
        (item: any) =>
          !(
            item.specimenTypeID === options.specimenTypeID &&
            item.panelID === options.panelID
          )
      );
    }
    setInputValue(updatedInputValue);
    console.log(
      updatedInputValue,
      options.specimenTypeID,
      options.panelID,
      "89765tryguhi"
    );
    let newInputs = assignFormValues(
      props?.Inputs,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      updatedInputValue,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    newInputs.then((res) => {
      let infectiousDataCopy = JSON.parse(
        JSON.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData &&
        props?.setInfectiousData([...infectiousDataCopy]);
    });
  };

  useEffect(() => {
    if (location?.state?.reqId) {
      convertPrefilledIcdDataToJson();
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.error && divElement.current) {
      divElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [props.error]);

  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <div id={props?.name} ref={divElement} tabIndex={-1}></div>
      <div className={props?.sectionDisplayType}>
        <div className="d-flex flex-wrap ">
          {props?.panels?.map(
            (panels: any) =>
              panels?.isVisible && (
                <div
                  className={`card shadow-sm mb-3 rounded border border-warning ${props?.displayType}`}
                  key={panels.panelID}
                >
                  <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h6>{panels?.panelName}</h6>
                    </div>
                  </div>
                  <div className="card-body py-md-4 py-3">
                    <div className="row">
                      {panels?.specimenSourceOption?.map(
                        (options: any, index: any) => (
                          <div
                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6"
                            key={options.specimenPreFix}
                          >
                            <label className="form-check form-check-inline form-check-solid m-0 mb-2">
                              <input
                                className="form-check-input h-20px w-20px"
                                type="checkbox"
                                id={props.id}
                                name={props.label}
                                onChange={(e: any) => {
                                  handleChange(
                                    e,
                                    options,
                                    e.target.checked,
                                    panels.panelName
                                  );
                                }}
                                checked={options.isSelected}
                                disabled={props?.disabled}
                              />
                              {options.specimenType}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default PanelsCheckboxSpecimenSource;
