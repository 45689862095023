import { TableCell, TableRow } from "@mui/material";
import RequisitionType from "../../../../Services/Requisition/RequisitionTypeService";

function DownloadRow({ row, ShowBlob }: any) {
  return (
    <>
      <TableRow className="h-30px" key={row.id}>
        <TableCell sx={{ width: "max-content" }}>
          <div className="d-flex justify-content-between cursor-pointer">
            <div
              style={{
                width: "max-content",
              }}
            >
              {row.fileName}
            </div>
          </div>
        </TableCell>

        <TableCell
          sx={{
            width: "max-content",
          }}
        >
          <div className="d-flex justify-content-between cursor-pointer" id="">
            {row.fileDate}
          </div>
        </TableCell>
        <TableCell
          sx={{
            width: "max-content",
          }}
        >
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-icon btn-sm fw-bold btn-success btn-icon-light"
              disabled={row.blobURL == null ? true : false}
              onClick={() => ShowBlob(row.blobURL)}
            >
              <i className="fa fa-download"></i>
            </button>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}

export default DownloadRow;
