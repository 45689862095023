import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { AutocompleteStyle } from "../../../Utils/MuiStyles/AutocompleteStyles";
import BreadCrumbs from "../../../Utils/Common/Breadcrumb";
import Pickup from "./Pickup";
import AddNewPickup from "./Pickup/AddNewPickup";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InsuranceService from "../../../Services/InsuranceService/InsuranceService";
import { useForm } from "react-hook-form";
import { useCourierContext } from "../../../Shared/CourierContext";
import Shipment from "./Shipment";
import AddNewShipment from "./Shipment/AddNewShipment";
import ShipmentTracking from "./ShipmentTracking";
import PickupArchive from "./PickupArchive";
import ShipmentArchive from "./ShipmentArchive";
import ShipmentTrackingArchive from "./ShipmentTrackingArchive";

const TabSelected = styled(Tab)(AutocompleteStyle());
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export type CourierNameI = "UPS" | "FedEx";

const UpsPickupAndShipment: React.FC<{}> = () => {
  const {
    setCourierName,
    setSearchRequest,
    setSearchRequestShipment,
    setSearchRequestShipmentTracking,
    setTabs,
    tabs,
  } = useCourierContext();

  const params = useParams().courierName;
  const navigate = useNavigate();
  useEffect(() => {
    let courier: CourierNameI | null = null;
    if (params === "UPS" || params === "FedEx") {
      courier = params;
      setCourierName(courier);
      setSearchRequest((prev: any) => ({
        ...prev,
        courierName: courier,
      }));
      setSearchRequestShipment((prev: any) => ({
        ...prev,
        courierName: courier,
      }));
      setSearchRequestShipmentTracking((prev: any) => ({
        ...prev,
        courierName: courier,
      }));
      setModalShow(false);
      setModalShow1(false);
      setTabs(0);
    } else {
      navigate("/pickup-shipment/UPS");
    }
  }, [params]);

  const handleTabChanged = (event: React.SyntheticEvent, newValue: number) => {
    setTabs(newValue);
    setModalShow(false);
    setModalShow1(false);
  };
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);

  return (
    <>
      <div className="app-toolbar py-2 py-lg-3">
        <div className="app-container container-fluid d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center">
          <BreadCrumbs />
        </div>
      </div>
      <div className="d-flex flex-column flex-column-fluid">
        <div className="app-content flex-column-fluid">
          <div className="app-container container-fluid">
            <AddNewPickup modalShow={modalShow} setModalShow={setModalShow} />
            <AddNewShipment
              setModalShow={setModalShow1}
              modalShow={modalShow1}
            />
            <Tabs
              value={tabs}
              onChange={handleTabChanged}
              className="min-h-auto"
              TabIndicatorProps={{ style: { background: "transparent" } }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                "& .MuiTabs-scrollButtons": {
                  width: 0,
                  transition: "width 0.7s ease",
                  "&:not(.Mui-disabled)": {
                    width: "48px",
                  },
                },
              }}
            >
              <TabSelected
                label="Pickup"
                {...a11yProps(0)}
                className="fw-bold text-capitalize"
              />
              <TabSelected
                label="Shipment"
                {...a11yProps(1)}
                className="fw-bold text-capitalize"
              />
              <TabSelected
                label="Shipment Tracking"
                {...a11yProps(2)}
                className="fw-bold text-capitalize"
              />
              <TabSelected
                label="Pickup Archive"
                {...a11yProps(3)}
                className="fw-bold text-capitalize"
              />
              <TabSelected
                label="Shipment Archive"
                {...a11yProps(4)}
                className="fw-bold text-capitalize"
              />
              <TabSelected
                label="Shipment Tracking Archive"
                {...a11yProps(5)}
                className="fw-bold text-capitalize"
              />
            </Tabs>
            <div className="card rounded-top-0 shadow-none">
              <div className="card-body py-2">
                <TabPanel value={tabs} index={0}>
                  <Pickup setModalShow={setModalShow} />
                </TabPanel>
                <TabPanel value={tabs} index={1}>
                  <Shipment setModalShow={setModalShow1} />
                </TabPanel>
                <TabPanel value={tabs} index={2}>
                  <ShipmentTracking />
                </TabPanel>
                <TabPanel value={tabs} index={3}>
                  <PickupArchive />
                </TabPanel>
                <TabPanel value={tabs} index={4}>
                  <ShipmentArchive />
                </TabPanel>
                <TabPanel value={tabs} index={5}>
                  <ShipmentTrackingArchive />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpsPickupAndShipment;
