import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { getFacilitiesLookup } from "../../../../Services/Marketing/BulletinBoardService";
import { SAlesRepLookupApi } from "../../../../Services/Marketing/TrainingAids";
import {
  RequisitionReportSave,
  ScheduledReportDownload,
} from "../../../../Services/Requisition/RequisitionReports/RequisitionReport";
import DateAndTimeDuplicate from "../../../../Shared/Common/DatePicker/DateAndTime";
import { reactSelectSMStyle, styles } from "../../../../Utils/Common";
import useLang from "./../../../../Shared/hooks/useLanguage";
import ByFacility from "./ByFacility";
import BySaleRep from "./BySaleRep";
import CheckBox from "./CheckBox";

interface Lookups {
  value: number;
  label: string;
}

interface radioVal {
  label: string;
  value: number;
}

const ReqReport = () => {
  const { t } = useLang();
  const radioValues: radioVal[] = [
    {
      label: "All Data",
      value: 0,
    },
    {
      label: "By Facility",
      value: 1,
    },
    {
      label: "By Sale Rep",
      value: 2,
    },
  ];

  const [showFacility, setShowFacility] = useState(false);
  const [showSaleRep, setShowSaleRep] = useState(false);
  const [parentChecked, setParentChecked] = useState(false);

  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  /*#####################-----Facility states Start-----###################*/

  const [facilityLookup, setFacilityLookup] = useState<Lookups[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Lookups[]>([]);
  const [selectedFacilitiesSearchTerm, setSelectedFacilitiesSearchTerm] =
    useState("");
  const [allFacilitiesSearchTerm, setAllFacilitiesSearchTerm] = useState("");

  /*#####################-----Facility states End-----###################*/
  /*#####################-----Salerep states Start-----###################*/
  const [salesReplookup, setSalesReplookup] = useState<Lookups[]>([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState<Lookups[]>([]);
  const [allRepsSearchTerm, setAllRepsSearchTerm] = useState("");
  const [selectedRepsSearchTerm, setSelectedSalesRepsSearchTerm] = useState("");
  /*#####################-----Salerep states End-----###################*/

  // ###  Date state
  const [filterData, setFilterData] = useState<any>({});

  // ## CheckBox  State
  const [childChecked, setChildChecked] = useState(Array(35).fill(false));

  const initialPostData = {
    id: 0,
    fileName: "",
    frequencyLabel: "",
    selectLabel: "",
  };
  const [postData, setpostData] = useState<any>(initialPostData);

  /*#####################-----Facility function Start-----###################*/
  useEffect(() => {
    const fetchFacilities = async () => {
      const facilities = await getFacilitiesLookup();
      setFacilityLookup(facilities.data);
    };
    fetchFacilities();
  }, [selectedValue]);

  const handleFacilityClick = (facility: Lookups) => {
    setSelectedFacilities((prevSelectedFacilities) => {
      if (
        prevSelectedFacilities.some(
          (selected) => selected.value === facility.value
        )
      ) {
        return prevSelectedFacilities;
      } else {
        return [...prevSelectedFacilities, facility];
      }
    });

    setFacilityLookup((prevFacilityLookup) =>
      prevFacilityLookup.filter((f) => f.value !== facility.value)
    );
  };

  const removeSelectedFacilities = (facility: Lookups) => {
    setSelectedFacilities((prevSelectedFacilities) =>
      prevSelectedFacilities.filter((f) => f.value !== facility.value)
    );
    setFacilityLookup((prevFacilityLookup) => [
      ...prevFacilityLookup,
      facility,
    ]);
  };
  const filteredSelectedFacilities = selectedFacilities.filter((facility) =>
    facility?.label
      ?.toLowerCase()
      .includes(selectedFacilitiesSearchTerm.toLowerCase())
  );

  const removeDuplicates = (arr: Lookups[]): Lookups[] => {
    const uniqueValues = new Set<number>();
    return arr.filter((item) => {
      if (uniqueValues.has(item.value)) {
        return false;
      } else {
        uniqueValues.add(item.value);
        return true;
      }
    });
  };
  const uniqueLookup = removeDuplicates(facilityLookup);
  const lookupForEdit = uniqueLookup.filter(
    (lookupItem: Lookups) =>
      !selectedFacilities.some(
        (selectedItem: Lookups) => selectedItem.value === lookupItem.value
      )
  );
  const filteredAllFacilities = lookupForEdit.filter((facility) =>
    facility.label.toLowerCase().includes(allFacilitiesSearchTerm.toLowerCase())
  );
  /*#####################-----Facility function End-----###################*/

  /*###################  SalesRep lookup & selectedSalesRep function Start ######################### */

  useEffect(() => {
    const fetchSalesRep = async () => {
      const salesRep: any = await SAlesRepLookupApi();
      setSalesReplookup(salesRep.data);
    };
    fetchSalesRep();
  }, [selectedValue]);

  const handleRepClick = (rep: Lookups) => {
    setSelectedSalesRep((prevSelectedSalesRep) => {
      if (
        prevSelectedSalesRep.some((selected) => selected.value === rep.value)
      ) {
        return prevSelectedSalesRep;
      } else {
        return [...prevSelectedSalesRep, rep];
      }
    });

    setSalesReplookup((prevSalesReplookup) =>
      prevSalesReplookup.filter((r) => r.value !== rep.value)
    );
  };

  const removeSelectedRep = (rep: Lookups) => {
    setSelectedSalesRep((prevSelectedSalesRep) =>
      prevSelectedSalesRep.filter((r) => r.value !== rep.value)
    );
    setSalesReplookup((prevSalesReplookup) => [...prevSalesReplookup, rep]);
  };
  const filteredSelectedReps = selectedSalesRep.filter((rep) =>
    rep?.label?.toLowerCase().includes(selectedRepsSearchTerm.toLowerCase())
  );

  const saleRepremoveDuplicates = (arr: Lookups[]): Lookups[] => {
    const uniqueValues = new Set<number>();
    return arr.filter((item) => {
      if (uniqueValues.has(item.value)) {
        return false;
      } else {
        uniqueValues.add(item.value);
        return true;
      }
    });
  };
  //##########  Remove duplicates from the lookup array ############
  const saleRepuniqueLookup = saleRepremoveDuplicates(salesReplookup);

  const saleReplookupForEdit = saleRepuniqueLookup.filter(
    (lookupItem: Lookups) =>
      !selectedSalesRep.some(
        (selectedItem: Lookups) => selectedItem.value === lookupItem.value
      )
  );

  const filteredAllReps = saleReplookupForEdit.filter((facility) =>
    facility.label.toLowerCase().includes(allRepsSearchTerm.toLowerCase())
  );
  /*###################  SalesRep lookup & selectedSalesRep function End ######################### */

  const handleParentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setParentChecked(checked);
    setChildChecked(childChecked.map(() => checked));
  };
  const selectlookup = [
    { value: 5, label: t("date of collection") },
    { value: 10, label: t("received date") },
    { value: 50, label: t("validated date") },
  ];
  const frequencylookup = [
    { value: 1, label: t("Daily") },
    { value: 2, label: t("Weekly") },
    { value: 3, label: t("Monthly") },
  ];

  const handleChildChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      const updatedChildChecked = [...childChecked];
      updatedChildChecked[index] = checked;
      setChildChecked(updatedChildChecked);
      setParentChecked(updatedChildChecked.every(Boolean));
    };
  const [openalert, setOpenAlert] = useState(false);
  const handleCloseAlert = () => setOpenAlert(false);
  const handleClickOpen = () => {
    setOpenAlert(true);
  };

  const handleChangeCategory = (e: any) => {
    setpostData((prevData: any) => ({
      ...prevData,
      selectLabel: e.label,
    }));
  };
  const handleChangeFrequency = (e: any) => {
    setpostData((prevData: any) => ({
      ...prevData,
      frequencyLabel: e.label,
    }));
  };

  const APISENDFacility = selectedFacilities.map((value: any) => value.value);
  const APISENDSaleRep = selectedSalesRep.map((value: any) => value.value);
  /*#####################-----Scheduled Report API Start-----###################*/

  const PostApiData = async () => {
    const data = {
      id: postData.id,
      reportName: postData.fileName,
      frequency: postData.frequencyLabel,
      scheduledDate: filterData.dateTo,
      facilityIds: APISENDFacility || null,
      salesRepIds: APISENDSaleRep || null,
      reportDataArray: {
        patientFirstName: childChecked?.[0],
        patientLastName: childChecked?.[1],
        patientDateOfBirth: childChecked?.[2],
        patientID: childChecked?.[3],
        patientGender: childChecked?.[4],
        patientAddress: childChecked?.[5],
        patientCity: childChecked?.[6],
        patientState: childChecked?.[7],
        patientZipCode: childChecked?.[8],
        patientEmail: childChecked?.[9],
        requisitionType: childChecked?.[10],
        dteOfCollection: childChecked?.[12],
        typeOfCollection: childChecked?.[13],
        recievedDate: childChecked?.[14],
        validatedDate: childChecked?.[15],
        status: childChecked?.[16],
        accessionNo: childChecked?.[17],
        insuranceType: childChecked?.[18],
        labCode: childChecked?.[19],
        primaryInsuranceProvider: childChecked?.[20],
        providerName: childChecked?.[21],
        groupID: childChecked?.[22],
        relationshipToInsure: childChecked?.[23],
        policyID: childChecked?.[24],
        policyHolderDOB: childChecked?.[25],
        facilityName: childChecked?.[26],
        facilityAddress: childChecked?.[27],
        facilityCity: childChecked?.[28],
        facilityState: childChecked?.[29],
        facilityZipCode: childChecked?.[30],
        facilityID: childChecked?.[31],
        physicianName: childChecked?.[32],
        npi: childChecked?.[33],
        saleRepName: childChecked?.[34],
      },
      reportType: postData.selectLabel,
      reportBy: selectedValue,
      isDeleted: false,
    };
    let resp = await RequisitionReportSave(data);
  };

  /*#####################-----Scheduled Report API End-----###################*/

  const handleSave = async () => {
    if (selectedValue === null) {
      toast.error(t("Select at least one option."));
      return;
    }

    if (selectedValue === 0 && childChecked.every((val) => !val)) {
      toast.error(t("Select at least one checkbox."));
      return;
    }

    if (
      selectedValue === 1 &&
      (!selectedFacilities || selectedFacilities.length === 0)
    ) {
      toast.error(t("Select at least one Facility."));
      return;
    }

    if (selectedValue === 2) {
      if (!selectedSalesRep || selectedSalesRep.length === 0) {
        toast.error(t("Select at least one Sale Rep."));
        return;
      }
    }

    if (!postData.fileName?.trim()) {
      toast.error(t("Please Select File Name."));
      return;
    }

    if (!postData.frequencyLabel?.trim()) {
      toast.error(t("Please Select Frequency."));
      return;
    }

    if (!postData.selectLabel?.trim()) {
      toast.error(t("Please select any data field."));
      return;
    }

    try {
      await PostApiData();
      resetState();
    } catch (error) {
      toast.error(t("An error occurred while saving data."));
    }
  };

  const resetState = () => {
    setSelectedValue(null);
    setFilterData({});
    setOpenAlert(false);
    setShowSaleRep(false);
    setShowFacility(false);
    setParentChecked(false);
    setpostData(initialPostData);
    setChildChecked(Array(35).fill(false));
  };

  const handleCancle = () => {
    // setSelectedValue(null);
    // setFilterData({});
    setOpenAlert(false);
    // setShowSaleRep(false);
    // setShowFacility(false);
    // setParentChecked(false);
    // setpostData(initialPostData);
    // setChildChecked(Array(35).fill(false));
  };

  /*#####################-----Scheduled Report Download API Start-----###################*/

  const ScheduledDownload = async () => {
    const obj = {
      reportBy: selectedValue,
      facilityIds: APISENDFacility || null,
      salesRepIds: APISENDSaleRep || null,
      reportData: {
        patientFirstName: childChecked?.[0],
        patientLastName: childChecked?.[1],
        patientDateOfBirth: childChecked?.[2],
        patientID: childChecked?.[3],
        patientGender: childChecked?.[4],
        patientAddress: childChecked?.[5],
        patientCity: childChecked?.[6],
        patientState: childChecked?.[7],
        patientZipCode: childChecked?.[8],
        patientEmail: childChecked?.[9],
        requisitionType: childChecked?.[10],
        dateOfCollection: childChecked?.[12],
        timeOfCollection: childChecked?.[13],
        recievedDate: childChecked?.[14],
        validatedDate: childChecked?.[15],
        status: childChecked?.[16],
        accessionNo: childChecked?.[17],
        insuranceType: childChecked?.[18],
        labCode: childChecked?.[19],
        primaryInsuranceProvider: childChecked?.[20],
        providerName: childChecked?.[21],
        groupID: childChecked?.[22],
        relationshipToInsure: childChecked?.[23],
        policyID: childChecked?.[24],
        policyHolderDOB: childChecked?.[25],
        facilityName: childChecked?.[26],
        facilityAddress: childChecked?.[27],
        facilityCity: childChecked?.[28],
        facilityState: childChecked?.[29],
        facilityZipCode: childChecked?.[30],
        facilityID: childChecked?.[31],
        physicianName: childChecked?.[32],
        npi: childChecked?.[33],
        saleRepName: childChecked?.[34],
      },
      dateFrom: filterData.dateFrom,
      dateTo: filterData.dateTo,
      reportType: postData.selectLabel,
    };
    let res = await ScheduledReportDownload(obj);
    const fileContent = res.data.data.fileContents;
    const downloadLink = document.createElement("a");
    downloadLink.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileContent}`;
    downloadLink.download = "Requisition Report.xlsx";
    downloadLink.click();
    console.log(res, "respresp");
  };

  /*#####################-----Scheduled Report Download API End-----###################*/
  const validateDownload = () => {
    if (selectedValue === null) return t("Select at least one option.");
    if (Object.keys(filterData).length === 0) {
      return t("Please Enter Date.");
    }
    if (selectedValue === 0 && childChecked.every((val) => !val))
      return t("Select at least one checkbox.");
    if (
      selectedValue === 1 &&
      (!selectedFacilities || selectedFacilities.length === 0)
    )
      return t("Select at least one Facility.");
    if (
      selectedValue === 2 &&
      (!selectedSalesRep || selectedSalesRep.length === 0)
    )
      return t("Select at least one Sale Rep.");
    if (!postData.selectLabel?.trim())
      return t("Please select any data field.");
    return null;
  };

  const handleDownload = async () => {
    const errorMessage = validateDownload();
    if (errorMessage) {
      toast.error(errorMessage);
      return;
    }
    await ScheduledDownload();
    setSelectedValue(null);
    setFilterData({});
    setShowSaleRep(false);
    setShowFacility(false);
    setParentChecked(false);
    setSelectedSalesRep([]);
    setSelectedFacilities([]);
    setpostData(initialPostData);
    setChildChecked(Array(35).fill(false));
  };

  const handleRadioChange = (value: number) => {
    setSelectedValue(value);

    switch (value) {
      case 0:
        setShowSaleRep(false);
        setShowFacility(false);
        setSelectedSalesRep([]);
        setSelectedFacilities([]);
        break;
      case 1:
        setShowSaleRep(false);
        setShowFacility(true);
        setSelectedSalesRep([]);
        break;
      case 2:
        setShowSaleRep(true);
        setShowFacility(false);
        setSelectedFacilities([]);
        break;
      default:
        setShowSaleRep(false);
        setShowFacility(false);
        setSelectedSalesRep([]);
        setSelectedFacilities([]);
        break;
    }
  };

  return (
    <>
      <div className="card">
        <div className="row card-header px-0">
          <div className="card-heading ">
            <h6 className="required">{t("Select Report")}</h6>
          </div>
          <div className="row">
            <div className="col-sm-8 pt-2">
              <div className="row d-flex justify-content-between">
                {radioValues.map((radVal) => (
                  <div key={radVal.value} className="col-xs-4 mb-2">
                    <label
                      className="form-check form-check-sm form-check-solid  w-fit-content"
                      style={{ width: "fit-content" }}
                    >
                      <input
                        className="form-check-input h-20px w-20px rounded-4"
                        type="radio"
                        value={radVal.value}
                        name="resultType"
                        checked={selectedValue === radVal.value}
                        onChange={() => handleRadioChange(radVal.value)}
                      />
                      {radVal.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {showFacility ? (
          <ByFacility
            handleFacilityClick={handleFacilityClick}
            filteredAllFacilities={filteredAllFacilities}
            allFacilitiesSearchTerm={allFacilitiesSearchTerm}
            removeSelectedFacilities={removeSelectedFacilities}
            filteredSelectedFacilities={filteredSelectedFacilities}
            setAllFacilitiesSearchTerm={setAllFacilitiesSearchTerm}
            selectedFacilitiesSearchTerm={selectedFacilitiesSearchTerm}
            setSelectedFacilitiesSearchTerm={setSelectedFacilitiesSearchTerm}
          />
        ) : null}
        {showSaleRep ? (
          <BySaleRep
            handleRepClick={handleRepClick}
            filteredAllReps={filteredAllReps}
            allRepsSearchTerm={allRepsSearchTerm}
            removeSelectedRep={removeSelectedRep}
            filteredSelectedReps={filteredSelectedReps}
            setAllRepsSearchTerm={setAllRepsSearchTerm}
            selectedRepsSearchTerm={selectedRepsSearchTerm}
            setSelectedSalesRepsSearchTerm={setSelectedSalesRepsSearchTerm}
          />
        ) : null}
        <div className="row card-body px-0 py-3">
          <div className="card-heading">
            <h6>{t("Select Data")}</h6>
            <label className="form-check form-check-sm form-check-solid  ">
              <input
                name="urgent"
                className="form-check-input h-20px w-20px"
                type="checkbox"
                checked={parentChecked}
                onChange={handleParentChange}
              />
              {t("Check All")}
            </label>
          </div>
        </div>
        <CheckBox
          childChecked={childChecked}
          handleChildChange={handleChildChange}
        />
      </div>

      <div className="card-footer px-0 pt-2 pb-0">
        <div className="card-heading">
          <h6>{t("Filter")}</h6>
        </div>

        <div className="d-flex flex-wrap justify-content-start align-items-center mb-2 gap-2">
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="d-flex align-items-center">
              <span className="fw-400 mr-3">{t("Date")}</span>
              <DateAndTimeDuplicate
                setFilterData={setFilterData}
                filterData={filterData}
              />
            </div>
            <div className="d-flex align-items-center gap-2">
              <Select
                menuPortalTarget={document.body}
                styles={reactSelectSMStyle}
                theme={(theme: any) => styles(theme)}
                options={selectlookup}
                name="selectLabel"
                onChange={handleChangeCategory}
                value={
                  selectlookup.find(
                    (option) => option.label === postData.selectLabel
                  ) || null
                }
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <button
              className="btn btn-icon btn-sm fw-bold btn-warning btn-icon-light"
              onClick={() => {
                handleDownload();
              }}
            >
              <i className="fa fa-download"></i>
            </button>
            <button
              className="btn btn-info btn-sm fw-bold search d-block"
              onClick={handleClickOpen}
            >
              {t("Schedule Reports")}
            </button>
          </div>
        </div>
      </div>
      <BootstrapModal
        BootstrapModal
        show={openalert}
        onHide={handleCloseAlert}
        backdrop="static"
      >
        <BootstrapModal.Header closeButton className="bg-light-primary m-0 p-5">
          <h4>{t("Please select a Frequency and Time")}</h4>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="py-3">
          <div>
            <label>{t("Name")}</label>
            <input
              type="text"
              name="fileName"
              className="form-control bg-white mb-3 mb-lg-0 min-w-150px w-100 rounded-2 fs-8 h-30px"
              placeholder={t("Name")}
              value={postData.fileName}
              onChange={(e) =>
                setpostData((oldData: any) => ({
                  ...oldData,
                  fileName: e.target.value,
                }))
              }
            />
          </div>
          <div className="mt-3">
            <label>{t("Frequency")}</label>
            <Select
              menuPortalTarget={document.body}
              theme={(theme: any) => styles(theme)}
              styles={reactSelectSMStyle}
              options={frequencylookup}
              name="frequencylookup"
              value={frequencylookup.find(
                (option: any) => option.value === postData.frequencyLabel
              )}
              onChange={handleChangeFrequency}
            />
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="p-0">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancle}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="btn btn-danger m-2"
            onClick={handleSave}
          >
            {t("Save")}
          </button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export default ReqReport;
