import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { savePdfUrls } from "Redux/Actions/Index";
import useLang from "Shared/hooks/useLanguage";
import { assignFormValues } from "Utils/Auth";

function ReportTemplates(props: any) {
  const { t } = useLang();
  const dispatch = useDispatch();

  const { data, setInputs } = props;

  const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);
  const [selectedReqType, setSelectedReqType] = useState<string>("");

  const handleTemplateChange = (
    id: number,
    url: string,
    e: any,
    label: string
  ) => {
    setSelectedTemplates((prevTemplates: any[]) => {
      const updatedTemplates = [...prevTemplates];
      const index = updatedTemplates.findIndex(
        (template) => template.label === label
      );

      const newTemplate = {
        label: label,
        id: id,
        name: e.target.value,
        fileUrl: url,
      };
      setSelectedReqType(label);
      // If the label already exists, update it
      if (index > -1) {
        updatedTemplates[index] = newTemplate;
      } else {
        // Otherwise, add a new selection
        updatedTemplates.push(newTemplate);
      }

      return updatedTemplates;
    });
  };

  useEffect(() => {
    const handleReportSelection = async () => {
      let newInputs = await assignFormValues(
        props.Inputs,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        selectedTemplates,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      console.log(newInputs, "newInputs");

      setInputs(newInputs);
    };
    handleReportSelection();
  }, [selectedTemplates]);

  console.log(data, "9823na83");

  return (
    <div className="card mt-2">
      <div className="col-lg-12 col-sm-12 col-md-12 col-xxl-12">
        <div className="row">
          <h6 className="text-primary mb-5">
            {t("Select the required report template for resulting")}
          </h6>
          <div className="col-lg-2 col-sm-2 col-md-2 col-xxl-2">
            {[
              ...new Set(data.options.map((template: any) => template.label)),
            ].map((uniqueReqType: any) => (
              <div key={uniqueReqType}>
                <span className="fw-500">{uniqueReqType}</span>
                <div className="mt-5">
                  {data.options
                    .filter((template: any) => template.label === uniqueReqType)
                    .map((template: any) => (
                      <div key={template.name} className="mb-3">
                        <label className="form-check form-check-inline form-check-solid me-5">
                          <input
                            className="form-check-input ifuser"
                            type="radio"
                            name={`template_${uniqueReqType}`}
                            value={template.name}
                            checked={
                              selectedTemplates?.find(
                                (sel) => sel.label === uniqueReqType
                              )?.name === template.name
                            }
                            onChange={(e: any) => {
                              handleTemplateChange(
                                template.id,
                                template.value,
                                e,
                                uniqueReqType
                              );
                            }}
                          />
                          {template.name}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
          {/* {selectedTemplates.length > 0 && selectedReqType && (
            <div className="col-lg-10 col-sm-10 col-md-10 col-xxl-10 d-flex justify-content-sm-end justify-content-center">
              <div className="card card-bordered shadow-xs bg-light-secondary">
                <div className="d-flex justify-content-around">
                  <div className="card-body h-md-375px px-5 py-5">
                    {(() => {
                      const selected = selectedTemplates.find(
                        (template) => template.label === selectedReqType
                      );

                      if (!selected) return null;

                      switch (selected.name.toLowerCase()) {
                        case "rpp":
                          return (
                            <Link
                              className="h-100"
                              to={`/docs-viewer`}
                              target="_blank"
                              onClick={() => {
                                dispatch(savePdfUrls(selected.value));
                              }}
                            >
                              <img
                                className="h-100"
                                src="/RPP.png"
                                alt="template"
                              />
                            </Link>
                          );
                        case "uti":
                          return (
                            <Link
                              className="h-100"
                              to={`/docs-viewer`}
                              target="_blank"
                              onClick={() => {
                                dispatch(savePdfUrls(selected.value));
                              }}
                            >
                              <img
                                className="h-100"
                                src="/UTI.png"
                                alt="template"
                              />
                            </Link>
                          );
                        case "wound":
                          return (
                            <Link
                              className="h-100"
                              to={`/docs-viewer`}
                              target="_blank"
                              onClick={() => {
                                dispatch(savePdfUrls(selected.value));
                              }}
                            >
                              <img
                                className="h-100"
                                src="/Wound.png"
                                alt="template"
                              />
                            </Link>
                          );
                        case "id standard":
                          return (
                            <Link
                              className="h-100"
                              to={`/docs-viewer`}
                              target="_blank"
                              onClick={() => {
                                dispatch(savePdfUrls(selected.value));
                              }}
                            >
                              <img
                                className="h-100"
                                src="/IDStandard.png"
                                alt="template"
                              />
                            </Link>
                          );
                        case "tox template":
                          return (
                            <Link
                              className="h-100"
                              to={`/docs-viewer`}
                              target="_blank"
                              onClick={() => {
                                dispatch(savePdfUrls(selected.value));
                              }}
                            >
                              <img
                                className="h-100"
                                src="/Tox_Standard.jpg"
                                alt="template"
                              />
                            </Link>
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default ReportTemplates;
