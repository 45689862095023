import { LoadingButton } from "@mui/lab";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { refreshToken } from "../../HttpClient";
import { getToken } from "../../Utils/Auth";
import useIdleTimeout from "../hooks/useIdle";
import useLogoutListener from "../hooks/useLogoutListener";
import useLang from "./../hooks/useLanguage";
import CountdownTimer from "./CountdownTimer";

export default function AuthDialog() {
  const { t } = useLang();
  const idleTime = 300000;
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = getToken();
  const logout = useLogoutListener();

  const handleIdle = () => {
    if (token) {
      setOpenModal(true);
      localStorage.setItem("openModal", "true");
    }
  };

  const handleLogout = async () => {
    logout();
    setOpenModal(false);
    localStorage.setItem("openModal", "false");
  };

  const { reset } = useIdleTimeout({
    onIdle: handleIdle,
    onLogout: handleLogout,
    idleTime,
  });

  const Stay = async () => {
    setLoading(true);
    reset();

    const _token = await refreshToken();
    if (_token) {
      setLoading(false);
    }
    setOpenModal(false);

    localStorage.setItem("continueClicked", Date.now().toString());
    localStorage.setItem("openModal", "false");
  };

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "resetTime") {
        reset();
      } else if (event.key === "openModal") {
        setOpenModal(event.newValue === "true");
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [reset]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BootstrapModal
      show={openModal}
      backdrop="static"
      keyboard={false}
      fullScreen={fullScreen}
    >
      <BootstrapModal.Body>
        <CountdownTimer
          initialTime={idleTime}
          isActive={openModal}
          onTimeout={handleLogout}
        />
        <span className="d-flex align-items-center flex-column mt-5 font-weight-bold">
          {t("Your session is about to expire...")}
        </span>
      </BootstrapModal.Body>
      <div className="d-flex align-items-center justify-content-center gap-5 pb-10">
        <LoadingButton
          onClick={Stay}
          loadingIndicator={<CircularProgress color="inherit" size={24} />}
          loading={loading}
          variant="contained"
          color="success"
        >
          {t("Continue")}
        </LoadingButton>

        <button
          className="btn btn-sm btn-danger"
          onClick={handleLogout}
          autoFocus
        >
          {t("Logout")}
        </button>
      </div>
    </BootstrapModal>
  );
}
