const types = {
  SET_USER_INFO: "SET_USER_INFO",
  SET_SHOW_COLUMN: "SET_SHOW_COLUMN",
  SET_HEADERS_KEY: "SET_HEADERS_KEY",
  SET_LAB_LOGO: "SET_LAB_LOGO",
  SET_REQUSITION_DATA: "SET_REQUSITION_DATA",
  SET_SELECTED_REQUSITION_DATA: "SET_SELECTED_REQUSITION_DATA",
  SET_FACILITY: "SET_FACILITY",
  SET_MULTI_FACILITIES: "SET_MULTI_FACILITIES",
  SET_FACILITY_CLAIMS: "SET_FACILITY_CLAIMS",
  SET_FACILITY_USER_TYPE: "SET_FACILITY_USER_TYPE",
  SET_LOGGEDIN_USER_INFO: "SET_LOGGEDIN_USER_INFO",
  SET_DECRYPTION_ID: "SET_DECRYPTION_ID",
  SET_LAB_NAME: "SET_LAB_NAME",
  SAVE_URLS: "SAVE_URLS",
  TOKEN_DATA: "TOKEN_DATA",
  SELECTED_TENANT_INFO: "SELECTED_TENANT_INFO",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SET_LINKS_URLS: "SET_LINKS_URLS",
  SET_SELECTED_MENU: "SET_SELECTED_MENU",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  SET_ADMIN_USER_ID: "SET_ADMIN_USER_ID",
  SET_BASE_URL: "SET_BASE_URL",
  SET_LABTYPE: "SET_LABTYPE",
  SET_WEB_INFO: "SET_WEB_INFO",
  RESET_STORE: "RESET_STORE",
  SET_PHYSICIAN_SIGN: "SET_PHYSICIAN_SIGN",
  ADD_BILLING_INFO: "ADD_BILLING_INFO",
  REMOVE_BILLING_INFO: "REMOVE_BILLING_INFO",
  CLEAR_BILLING_INFO: "CLEAR_BILLING_INFO",
};

export default types;
